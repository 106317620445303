import React, { useEffect, useState } from 'react'
import PasswordField from '../../../widgets/password/password'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SetPasswordProps } from '../../../types/center-page';
import { FormField, FormGroup } from '../../../utils/form-generator';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { checkPassword } from '../../../utils/util-functions';
import { gql, useQuery } from '@apollo/client';
import { useParams, useSearchParams } from 'react-router-dom';


const QUERY = gql`
query CheckTempkey($tempKey: String) {
  checkTempkey(tempKey: $tempKey) {
    success
  }
}
`


export default function SetPasswordHoc({ setPassword }: SetPasswordProps) {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isValidRequest, setIsValidRequest] = useState('loading');
  const { refetch: latesttokenfetch } = useQuery(QUERY, { skip: true });
  const { key } = useParams();






  const handleSendCode = (data: any) => {
    if (data.password === data.confirmPassword) {
      setPassword?.({ password: data.password, confirmPassword: data.confirmPassword })
    } else {
      enqueueSnackbar(t("Password mismatch. Please re-enter the password."), { variant: "error" });
    }
  }




  useEffect(() => {
    getTokenExpire();
  }, [key])

  const getTokenExpire = async () => {

    const { data } = await latesttokenfetch({
      tempKey: key
    });


    if (data.checkTempkey.success) {
      setIsValidRequest('valid');
    } else {
      setIsValidRequest('invalid');
    }

    return data;
  }



  if(isValidRequest === 'loading') {
    return <></>
  }


  if (isValidRequest === 'invalid') {
    return (<>
      <Typography variant="h3" component="div" gutterBottom >
        {t('Token has expired')}
      </Typography>
    </>)
  }


  else {
    return (

      <FormGroup onSubmit={handleSendCode}>
        <div>
          <Typography variant="h3" component="div" gutterBottom sx={{ mb: 12 }}>
            {t('Set Password')}
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom sx={{ mb: 15 }}>
            {t('You can now make your password')}
          </Typography>
          <FormField name='password' validator={(value: string) => {
            if (!value) return "Password is required."
            let error = checkPassword(value);
            if (error) return error;
            return null
          }} validateOnChange={false}>
            <PasswordField
              id="password"
              label={t("New Password")}
              wrapperSx={{ mb: 16 }}
              variant="outlined"
              fullWidth={true}
            />
          </FormField>
          <FormField name='confirmPassword' validator={(value: string) => {
            if (!value) return "Password is required."
            let error = checkPassword(value);
            if (error) return error;
            return null
          }} validateOnChange={false}>
            <PasswordField
              id="password"
              label={t("Confirm Password")}
              wrapperSx={{ mb: 16 }}
              variant="outlined"
              fullWidth={true}
            />
          </FormField>
          <Button variant="contained" type="submit" >{t('SET PASSWORD')}</Button>
        </div>
      </FormGroup>
    )
  }


}

