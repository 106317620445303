import React, { useTransition, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Button, Divider, Grid, MenuItem } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/reducers/AppSlice';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';



const UPDATE_SLOT_DETAILS = gql`mutation Mutation($eventId: Int!, $slotId: Int!, $jerseyNumber: Int!, $playerId: Int!, $status: Boolean!, $slotPlayerId: Int) {
    updateSlotPlayerDetails(eventId: $eventId, slotId: $slotId, jerseyNumber: $jerseyNumber, playerId: $playerId, status: $status, slotPlayerId: $slotPlayerId) {
      success
      message
      errors
    }
  }`


function SlotEditDetails({ slot, onSave, onClose, eventId, slotId }: any) {

    const [updateSlotDetails] = useMutation(UPDATE_SLOT_DETAILS);
    const { enqueueSnackbar } = useSnackbar();
    const [resetField, setResetField] = useState<Function | undefined>(undefined);

    const dispatch = useDispatch();
    const { t } = useTranslation();



    const handleSubmit = async (newslot: any, callback: Function) => {

        const variables = {
            eventId: eventId,
            slotId: slotId,
            playerId: slot.id,
            jerseyNumber: parseInt(newslot.jerseyNumber),
            status: newslot.status === "0" ? false : true,
            slotPlayerId:slot.slotPlayerId
        }

        try {
            const { data } = await updateSlotDetails({
                variables: variables
            });

            if (data.updateSlotPlayerDetails.errors?.length > 0) {
                console.error('Error:', data.updateSlotPlayerDetails.errors);
                const errorIndex = data.updateSlotPlayerDetails.errors.length
                enqueueSnackbar(data.updateSlotPlayerDetails.errors[errorIndex-1], { variant: "error" });
                callback();

            } else {
                enqueueSnackbar(t("Slot player details updated successfully"), { variant: "success" });
                onSave();
            }
        } catch (error) {
            console.error('Mutation error:', error);
            callback();
        }


    };

    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
                <FormField name='jerseyNumber' value={slot?.slotPlayerJerseyNumber ?? ""} validator={(value: string) => {
                    if (!value) return "JerseyNumber is required."
                    return null
                }} validateOnChange={false}>
                    <TextField id="jerseyNumber"
                        label={t("Jersey Number")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>

                <FormField name='status' value={slot?.slotPlayerStatus ?? ""} validator={(value: string) => {
                    if (!value) return "Status is required."
                    return null
                }} validateOnChange={false}>
                    <TextField id="status"
                        label={t("Status")}
                        variant="outlined"
                        select
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    >
                        <MenuItem value="1" >
                            {t('Available')}
                        </MenuItem>

                        <MenuItem value="0" >
                            {t('Not Available')}
                        </MenuItem>
                    </TextField>
                </FormField>


                <Divider sx={{ mx: -24, mb: 20 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        {/* {editMode ? <Button type="submit" variant="contained" color="primary">{t('Save')}</Button> : <Button type="submit" variant="contained" color="primary">{t('Next')}</Button>} */}
                        <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
                    </Grid>
                </Grid>
            </FormGroup>
        </div>
    )
}

export default SlotEditDetails