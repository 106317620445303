import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { Box, MenuItem } from '@mui/material'
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { getWidget } from '../../../shared/widgets';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString } from '../../../shared/utils/util-functions';
import CustomerDetailModal from './customer-detail';
import CustomerStepper from './add-customer-stepper';
import Addassociation from './add-association';
import FilterCustomer from './filter-customer';
import RenewAssociation from './renew-association';


const DELETE_ASSOCIATION_QUERY = gql`mutation DeleteOneAssociation($deleteOneAssociationId: ID!) {
    deleteOneAssociation(id: $deleteOneAssociationId) {
      errors
      success
      association {
        associationName
      }
    }
  }`


type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void
};


function CustomerListPage() {
    const [page, setPage] = React.useState(1);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [renewModalOpen, setRenewModalOpen] = React.useState(false);
    const [selectedUser, setSelectedUser]: any[] = React.useState(null);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const [editMode, setEditMode]: any = React.useState(false);
    const [searchText, setSearchText] = useState(null);
    const childRef = useRef<ChildRef | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [deleteAssociation] = useMutation(DELETE_ASSOCIATION_QUERY);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [filterData, setFilterData]: any = React.useState({});
    const [associationDetailInfo, setAssociationDetailInfo] = React.useState({});
    const [associationDetailModal, setAssociationDetailModal] = React.useState(false);


    const dispatch = useDispatch();
    const { t } = useTranslation()
    const pageMeta = useContext(PageMetaContext);

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Customer");
        }
    }, [pageMeta]);

    const handleRefetch = () => {

        childRef.current?.refetchData({
            search: searchText ?? "",
            subcategoryId: filterData?.subcategoryId
        });
    };


    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedUser) return;
        const variables = {
            deleteOneAssociationId: selectedUser?.Association?.id,
        }

        try {
            const { data } = await deleteAssociation({
                variables: variables
            });
            setSelectedUser(null);

            if (data.deleteOneAssociation.errors?.length > 0) {
                console.error('Error:', data.deleteOneAssociation.errors);
                enqueueSnackbar(data.deleteOneAssociation.errors[0], { variant: "error" });
            } else {

                childRef?.current?.handleDeleterowPagination()
                enqueueSnackbar(t("Association deleted successfully"), { variant: "success" });

            }
        } catch (error) {
            setSelectedUser(null);
            console.error('Mutation error:', error);
        }

    }

    const TableConfigAssociations: TableColumn[] = [
        {
            label: "Action",
            align: "left",
            width: 130,
            accessor: (row: any) => {

                return <>
                    <ActionMenu
                        button={{
                            type: "icon-button",
                            icon: <MoreVertOutlined />,
                            size: 'small',
                            color: 'primary',
                            sx: { color: 'white' }
                        }}
                        children={
                            [
                                <MenuItem onClick={(event) => {
                                    setSelectedUser(row);
                                    setAddModalOpen(true)
                                    event.stopPropagation();
                                    setEditMode(true)
                                }}>{t('Edit')}</MenuItem>,
                                <MenuItem onClick={(event) => {
                                    setSelectedUser(row);
                                    setRenewModalOpen(true)
                                    event.stopPropagation();
                                    setEditMode(true)
                                }}>{t('Renew')}</MenuItem>,
                                <MenuItem onClick={(event) => {
                                    setSelectedUser(row);
                                    setDeleteConfirmModal(true)
                                    event.stopPropagation();
                                }}>{t('Delete')}</MenuItem>
                            ]
                        }
                    />
                </>
            }
        }
        ,
        {
            label: "Customer",
            align: "left",
            accessor: (row: any) => row?.Association?.associationName
        },
        {
            label: "Package",
            align: "left",
            accessor: (row: any) => row?.Package?.packageName
        },
        {
            label: "Start Date",
            align: "left",
            accessor: (row: any) => row?.startDate
        },
        {
            label: "End Date",
            align: "left",
            accessor: (row: any) => row?.endDate
        },
        {
            label: "Events (opened)",
            align: "left",
            accessor: (row: any) => row?.eventCount
        },
    ]


    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }


    useUpdateEffect(() => {
        handleRefetch();
    }, [filterData, searchText], 300)


    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            setPage(1)
            setFilterData(filters.filters);
        }
    };


    const ActionBarConfig = useMemo(() => {
        return [
            {
                type: "filter-menu",
                children: <FilterCustomer filterData={filterData} onFilterChange={handleFilterChange} />,
                sx: {
                    order: 1
                }
            }
            ,
            {
                type: "search-input",
                placeholder: "Search",
                onChange: onSearchTextChange,
                responsive: {
                    xs: 12,
                    sm: "auto"
                },
                sx: {
                    order: {
                        xs: 3,
                        sm: 1
                    }
                }
            },
            {
                type: "button",
                label: t(responsiveString({ xs: "Add", sm: "Add Customer" })),
                startIcon: <Add />,
                onClick: () => {
                    setAddModalOpen(true)
                    setSelectedUser(null)
                    setEditMode(false)
                },
                sx: {
                    order: {
                        xs: 1,
                        sm: 2
                    }
                }
            }
        ]
    }, [filterData, decodedTokenData])



    const ASSOCATIONSQUERY = `query GetAllCustomerAssociations($search: String, $page: Int, $pageSize: Int, $categoryId: Int, $subcategoryId: Int) {
  getAllCustomerAssociations(search: $search, page: $page, pageSize: $pageSize, categoryId: $categoryId, subcategoryId: $subcategoryId) {
    totalAssociations
    success
    errors
    associations {
      status
      startDate
      id
      eventCount
      endDate
      adminCount
      Subcategory {
        id
      }
      Package {
        id
        packageName
      }
      Category {
        id
      }
      Association {
        id
        associationName
      }
    }
  }
}`


    const onAddUserModalClose = () => {
        setAddModalOpen(false);
        setRenewModalOpen(false);
        handleRefetch()
    }

    return (
        <div>
            <TablePageHoc actions={ActionBarConfig as WidgetProps[]} columns={TableConfigAssociations} query={ASSOCATIONSQUERY} ref={childRef}
                params={{
                    search: searchText,
                    categoryId: filterData?.categoryId,
                    subcategoryId: filterData?.subcategoryId
                }} sourceAccessor={(data) => data.getAllCustomerAssociations.associations} countAccessor={(data) => data.getAllCustomerAssociations.totalAssociations}
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }
                }
                onRowClick={(row) => {
                    setAssociationDetailInfo(row)
                    setAssociationDetailModal(true)
                }}
                detailPointer="pointer" />
            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedUser ? "Edit Customer" : "Add Customer",
                        size: 'md',
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        children: selectedUser ? <Addassociation association={selectedUser} editMode={editMode} onSave={() => onAddUserModalClose()} onClose={() => {
                            setAddModalOpen(false)

                        }} /> : <CustomerStepper role="customer" association={selectedUser} onSave={() => onAddUserModalClose()} onClose={() => {
                            setAddModalOpen(false)
                            handleRefetch()
                        }}
                        />,
                    })
            }
            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedUser ? "Edit Customer" : "Add Customer",
                        size: 'md',
                        modalOpen: renewModalOpen,
                        onClose: () => onAddUserModalClose(),
                        children: <RenewAssociation association={selectedUser} editMode={editMode} onSave={() => onAddUserModalClose()} onClose={() => {
                            setRenewModalOpen(false)
                        }} />
                    })
            }
            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the customer')} ${selectedUser?.Association?.associationName}? ${t('Once deleted, this action cannot be undone!')}`} />
            <CustomerDetailModal modalOpen={associationDetailModal} onClose={() => setAssociationDetailModal(false)} detail={associationDetailInfo} />
        </div>
    )
}

export default CustomerListPage