import React, { useContext, useEffect, useMemo, useRef } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useState } from "react";
import { Box, Button, Divider, Grid, MenuItem } from '@mui/material';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { responsiveString, splitCamelCase } from '../../../shared/utils/util-functions';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';




type ChildRef = {
    refetchData: (data: any) => void;
};



function OptionEvaluvatorList({ onClose, onSave, slotId, optionId, player }: any) {

    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState(null);
    const childRef = useRef<ChildRef | null>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData);
    const pageMeta = useContext(PageMetaContext);
    
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();



    const TableConfig = useMemo(() => {
        const config = [

            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row?.EventSlotUser?.User?.firstName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Last Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row?.EventSlotUser?.User?.lastName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Email",
                align: "left",
                accessor: (row: any) => row?.EventSlotUser?.User?.email
            },
            {
                label: "Contact Number",
                align: "left",
                accessor: (row: any) => row?.EventSlotUser?.User?.contactNumber
            },
            {
                label: "Role",
                align: "left",
                accessor: (row: any) => t(splitCamelCase(row?.EventSlotUser?.User?.role))
            },
            {
                label: "Comment",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row?.comment} minWidth={250} lineClamp={2} />
            },
        ]
        return config

    }, [])




    const QUERY = `query GetOptionEvaluatorList($slotId: Int, $slotPlayerId: Int, $optionId: Int) {
        getOptionEvaluatorList(slotId: $slotId, slotPlayerId: $slotPlayerId, optionId: $optionId) {
          errors
          success
          totalEvaluators
          evaluator {
            comment
            EventSlotUser {
              User {
                firstName
                lastName
                role
                email
                designation
                contactNumber
              }
            }
          }
        }
      }
      `

    return (
        <div>
            <TablePageHoc columns={TableConfig as TableColumn[]} ref={childRef} query={QUERY}

                params={{
                    search: searchText,
                    slotId: slotId,
                    slotPlayerId: player?player.slotPlayerId:null ,
                    optionId: parseInt(optionId)
                }}
                sourceAccessor={(data) => data.getOptionEvaluatorList.evaluator}
                countAccessor={(data) => data?.getOptionEvaluatorList?.totalEvaluators}
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }} />


            <Divider sx={{ mx: -24, mb: 20, mt: 20 }} />
            <Grid container justifyContent={"flex-end"}>
                <Grid item xs={"auto"}>
                    <Button type="button" variant="text" color="secondary" onClick={onClose}>{t('Close')}</Button>
                </Grid>

            </Grid>
        </div>
    )
}

export default OptionEvaluvatorList