import { useCallback, useEffect, useRef } from 'react';

const useDebounce = (callback: Function, delay=0) => {
    const savedCallback = useRef(callback);
  
    // Remember the latest callback
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    return useCallback(
      (...args:any) => {
        const handler = () => {
          savedCallback.current(...args);
        };
  
        const debounceTimer = setTimeout(handler, delay);
        return () => {
          clearTimeout(debounceTimer);
        };
      },
      [delay]
    );
  };
  
 export  const useUpdateEffect = (callback: Function, dependencies: any[], delay = 0) => {
    const hasMounted = useRef(false);
    const debouncedCallback = useDebounce(callback, delay);
  
    useEffect(() => {
      if (hasMounted.current) {
        return debouncedCallback();
      }
      hasMounted.current = true;
    }, dependencies);
  };