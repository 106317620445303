import React, { useEffect, useState } from 'react'
import { Button, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import OtpComponent from '../../../widgets/otp/otp';
import style from "./verificationEmailHoc.module.scss";
import { VerifyEmailProps } from '../../../types/center-page';
import { useTranslation } from 'react-i18next'
import { FormField, FormGroup } from '../../../utils/form-generator';


export const VerificationEmailHoc = ({ verifyOTP, resendOTP }: VerifyEmailProps) => {

  const [otp, setOtp] = React.useState('');
  const [time, setTime] = useState({ min: 5, sec: 0 })
  const [resetFieldMethod, setResetFieldMethod] = useState<Function | null>(null);

  useEffect(() => {
    startTimer()
  }, [])


  const startTimer = () => {
    const interval = setInterval(() => {


      setTime((prevTime) => {
        if (prevTime.sec > 0) {
          return { ...prevTime, sec: prevTime.sec - 1 };
        }
        if (prevTime.sec === 0) {
          if (prevTime.min === 0) {
            clearInterval(interval);
            return prevTime;
          } else {
            return { ...prevTime, sec: 59, min: prevTime.min - 1 };
          }
        }

        return prevTime;
      });

    }, 1000);

  }

  const { t } = useTranslation();



  return (
    <div className={style.wrapper}>
      <Typography variant="h3" component="div" gutterBottom color="primary" sx={{mb:12}} >
        {t('Verify Email Address')}
      </Typography>
      <Typography variant="subtitle1" component="p" gutterBottom sx={{ mb: 15 }}>
        {t('We sent a code to your email')}
      </Typography>
      <FormGroup onSubmit={(data) => {
        

        verifyOTP?.({ otp: data.otp });
      }} onFormInit={(resetField, resetForm) => setResetFieldMethod(() => resetField)}>
        <FormField name="otp" validator={(value: string, ...data) => {
          if (!value) return "OTP is required.";
          if (value.length < 6) return "OTP must be 6 digits long.";
          return null
        }}>
          <OtpComponent />
        </FormField>
        <Button variant="contained"
          sx={{ mt: 30, mb: 30 }} type='submit'>
          {t('Verify')}
        </Button>
        {(time.min > 0 || time.sec > 0) ? (
          <Typography variant="subtitle1" component="p" gutterBottom sx={{ mb: 15, color: "#A8B6C1" }}>
            {t('Time Remaining')}: {time.min < 10 ? `0${time.min}` : time.min}:
            {time.sec < 10 ? `0${time.sec}` : time.sec}
          </Typography>
        ) : (<div>
          <Link href="#"
          underline="hover"
          color="primary"
          sx={{ fontSize: '18px', lineHeight: '25px' }}
          onClick={() => {
            setTime({ min: 5, sec: 0 })
            resetFieldMethod?.('otp');
            startTimer()
            resendOTP?.();
          }}>
          {t('Resend OTP')}
        </Link>
        </div>)

        }
      </FormGroup>

    </div>
  )
}
