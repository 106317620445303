import React, { useEffect, useState } from 'react'
import BasicTabs from '../../../shared/hoc/tabs/Tabs'
import EventListPage from "../event-management/event-list";
import { useSelector } from 'react-redux';
import OngoingEventListPage from './ongoing-event-list';
import { gql, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next';


const GET_REQUEST_EVENTS_QUERY = gql`
  query Query {
      getRequestEvents {
        errors
        requestEvents {
          id
          ownerId
          from
          to
        }
        success
      }
    }
  `
const QUERY = gql`query CheckSubscription {
    checkSubscription {
      isPackageExpired
      success
      adminCount
      adminLimit
      eventCount
      eventLimit
      expiryDate
      package
    }
  }`



function EventListManagePage() {
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [requestEventId, setRequestEventId]: any = React.useState(null);
    const { refetch: latesteventfetch } = useQuery(GET_REQUEST_EVENTS_QUERY, { skip: true });
    const { refetch: packageRefetch } = useQuery(QUERY, { skip: true });
    const [packageData, setPackageData]: any = useState({});
    const { t } = useTranslation();


    useEffect(() => {
        getLatestEvents();
        getPackageDetails();
    }, [])

    const getPackageDetails = async () => {
        const { data } = await packageRefetch();
        console.log(data)
        setPackageData(data.checkSubscription);
        return data;
    }


    const getLatestEvents = async () => {
        const { data } = await latesteventfetch({
            search: "",
            page: null,
            pageSize: null
        });

        let reqId: any = null;

        if (data?.getRequestEvents.requestEvents.length > 0) {
            reqId = data?.getRequestEvents.requestEvents[0].id;
            setRequestEventId(reqId);
        }
        return data;
    }


    if (packageData?.isPackageExpired) {
        return (
            <div className="subscription-expired">
                <h2>{t("Your Subscription Has Ended")}</h2>
                <p>{t("Your current package has expired. To continue creating events, please renew your subscription.")}</p>
            </div>

        )
    }



    return (
        <div>
            {
                decodedTokenData.roles !== "Evaluator" ? (
                    <BasicTabs tabsData={[
                        {
                            label: 'Upcoming Events', content: () => (
                                <OngoingEventListPage season={requestEventId} />
                            )
                        },
                        {
                            label: 'Completed Events', content: () => (
                                <EventListPage season={requestEventId} />
                            )

                        },

                    ]} />

                ) : (<BasicTabs tabsData={[
                    {
                        label: 'Upcoming Events', content: () => (
                            <OngoingEventListPage />
                        )
                    }
                ]} />)
            }

        </div>
    )
}

export default EventListManagePage

