import React, { useContext, useEffect, useMemo, useRef } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from "react";
import { gql } from '@apollo/client';
import { Add, MoreVertOutlined, UploadFile } from '@mui/icons-material';
import AddPlayer from '../add-player/AddPlayer';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { Box, MenuItem, Typography } from '@mui/material';
import { getWidget } from '../../../shared/widgets';
import PlayerFilter from '../players-management/player-filter';
import { useDispatch, useSelector } from 'react-redux';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import SubmitModal from '../../../shared/hoc/modal/submit-modal';
import { startPageLoading, stopPageLoading } from '../../../store/reducers/AppSlice';
import { responsiveString } from '../../../shared/utils/util-functions';
import { Upload } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import FileUpload from '../upload-player/FileUpload';

const DELETE_USER_QUERY = gql`mutation DeletePlayerById($deletePlayerByIdId: ID!) {
    deletePlayerById(id: $deletePlayerByIdId) {
      player {
        firstName
      }
      errors
      success
    }
  }`

type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void
};

const SUBMIT_PLAYER_QUERY = gql`mutation Mutation($requestEventId: Int!) {
    submitPlayers(requestEventId: $requestEventId) {
      success
      errors
      players {
        firstName
      }
      totalPlayers
    }
  }`




const QUERY = `query GetPlayerList($clubId: ID, $requestEventId: ID, $associationId: ID, $isSubmitted: Boolean, $search: String, $page: Int, $pageSize: Int) {
    getPlayerList(clubId: $clubId, requestEventId: $requestEventId, associationId: $associationId, isSubmitted: $isSubmitted, search: $search, page: $page, pageSize: $pageSize) {
      errors
      players {
        Association {
            associationName
            id
        }
        Club {
            clubName
            id
        }
        contactPersonName
        RequestEventId
        createdAt
        dateOfBirth
        emailAddressImpFatherOrMother
        lastName
        goalKeeper
        id
        nationality
        playerAddress
        firstName
        postCode
        relationship
        strongFoot
        telephone
        updatedAt
        city
      }
      success
      totalPlayers
    }
  }
  `


const GET_REQUEST_EVENTS_QUERY = gql`
  query Query {
      getRequestEvents {
        errors
        requestEvents {
          id
          ownerId
        }
        success
      }
    }
  `



function RegisteredPlayersList() {
    const pageMeta = useContext(PageMetaContext);
    const { setPageName, setBreadcrumbs } = pageMeta ? pageMeta : { setPageName: undefined, setBreadcrumbs: undefined };
    const [requestEventId, setRequestEventId] = useState("");
    const { refetch: requestEventFetch } = useQuery(GET_REQUEST_EVENTS_QUERY, {
        skip: true
    });
    const childRef = useRef<ChildRef | null>(null);
    const [selectedUser, setSelectedUser]: any[] = React.useState(null);
    const [searchText, setSearchText] = useState('');
    const [filterData, setFilterData]: any = React.useState({});
    const [page, setPage] = React.useState(1);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const [submitConfirmModal, setSubmitConfirmModal] = React.useState(false);
    const [uploadModalOpen, setUploadModalOpen] = React.useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [deletePlayer] = useMutation(DELETE_USER_QUERY);
    const [submitPlayer] = useMutation(SUBMIT_PLAYER_QUERY);
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const requestEventParam = queryParams.get('request-event-id')
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [fileName, setFileName] = React.useState("");

    useEffect(() => {
        if (setPageName) {
            setPageName("Registered Players");
        }
    }, [setPageName]);


    useEffect(() => {
        if (requestEventParam) {
            setRequestEventId(requestEventParam);
        } else {
            getRequestEvents();
        }
    }, [requestEventParam]);

    useEffect(() => {

        const checkloader = location.pathname.includes('players-management');

        if (checkloader) {
            dispatch(stopPageLoading());
        }

    })


    const getRequestEvents = async () => {
        const { data } = await requestEventFetch();
        if (data && data.getRequestEvents.success && data.getRequestEvents.requestEvents.length > 0) {
            const firstRequestEvent = data.getRequestEvents.requestEvents[0];
            setRequestEventId(firstRequestEvent?.id);
        }
    }


    const TableConfig: TableColumn[] = useMemo(() => {
        return [
            {
                label: "Action",
                align: "left",
                accessor: (row: any) => {
                    return <>
                        <ActionMenu
                            button={{
                                type: "icon-button",
                                icon: <MoreVertOutlined />,
                                size: 'small',
                                color: 'primary',
                                sx: { color: 'white' }
                            }}
                            children={
                                [
                                    <MenuItem onClick={() => {
                                        setSelectedUser(row);
                                        setAddModalOpen(true)
                                    }}>{t('Edit')}</MenuItem>,
                                    <MenuItem onClick={() => {
                                        setSelectedUser(row);
                                        setDeleteConfirmModal(true)
                                    }}>{t('Delete')}</MenuItem>
                                ]
                            }
                        />
                    </>
                }
            }
            ,
            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => row.firstName
            },
            {
                label: "Last Name",
                align: "left",
                accessor: (row: any) => row.lastName
            },
            {
                label: "Date Of Birth",
                align: "left",
                accessor: (row: any) => row.dateOfBirth
            },
            {
                label: "Street",
                align: "left",
                accessor: (row: any) => row.playerAddress
            },
            {
                label: "Post Code",
                align: "left",
                accessor: (row: any) => row.postCode
            },
            {
                label: "City",
                align: "left",
                accessor: (row: any) => row.city
            },
            {
                label: "Contact Person Name",
                align: "left",
                accessor: (row: any) => row.contactPersonName
            },
            {
                label: "Relationship",
                align: "left",
                accessor: (row: any) => t(row.relationship)
            },
            {
                label: "Telephone",
                align: "left",
                accessor: (row: any) => row.telephone
            },
            {
                label: "Email",
                align: "left",
                accessor: (row: any) => row.emailAddressImpFatherOrMother
            },
            {
                label: "Nationality",
                align: "left",
                accessor: (row: any) => row.nationality
            },
            {
                label: "Association",
                align: "left",
                accessor: (row: any) => row.Association.associationName
            },
            {
                label: "Club",
                align: "left",
                accessor: (row: any) => row.Club.clubName
            },
            {
                label: "Strong Foot",
                align: "left",
                accessor: (row: any) => t(row.strongFoot)
            },
            {
                label: "Goal Keeper",
                align: "left",
                accessor: (row: any) => t(row.goalKeeper)
            }

        ]
    }, [])


    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    };



    useUpdateEffect(() => {
        handleRefetch();
    }, [searchText], 300)


    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            setPage(1)
            setFilterData(filters.filters);
        }
    };


    const [cookies] = useCookies(['token']);
    const token = cookies.token;

    const [selectedFile, setSelectedFile] = useState<File>();
    const [fileUpload, setFileUpload] = useState(false)



    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setFileUpload(true);
        setFileName(file.name);
    };


    const handleFileSubmit = async () => {

        try {

            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);

                // const BASE_URL = "http://localhost:6767";
                // const BASE_URL = "https://evaluva-api.bixbytessolutions.com";
                const BASE_URL = "https://evalua-live-api.bixbytessolutions.com";

                const response = await fetch(BASE_URL + '/excelFile-import-player?' + new URLSearchParams({
                    seasonId: requestEventId,
                }), {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData
                });
                const response_data = await response.json()
                if (response.status === 200) {
                    enqueueSnackbar(t("Player data uploaded successfully"), { variant: "success" });
                    setUploadModalOpen(false)
                    setSelectedFile(undefined);
                    setFileName("")
                    handleRefetch()
                } else if (response.status === 404) {
                    enqueueSnackbar(response_data.message, { variant: "error" });
                } else if (response.status === 400) {

                    enqueueSnackbar(response_data.message, { variant: "error" });
                }
            } else {

                enqueueSnackbar(t("No file selected"), { variant: "error" });
            }


        } catch (errors) {
            console.log('An error occurred while uploading the player list:', errors)
        }

    }



    const submitPlayers = async () => {
        const variables = {
            requestEventId: parseInt(requestEventId),
        }
        dispatch(startPageLoading());
        try {
            const { data } = await submitPlayer({
                variables: variables
            });


            if (data.submitPlayers?.errors?.length > 0) {
                dispatch(stopPageLoading());
                enqueueSnackbar(data.submitPlayers.errors[0], { variant: "error" });
            } else {

                enqueueSnackbar(t("Player Submitted successfully"), { variant: "success" });
                handleRefetch();
            }
        } catch (error) {
            dispatch(stopPageLoading());
            console.error('Mutation error:', error);
        }

    }

    const ActionBarConfig = useMemo(() => {


        const baseConfig: WidgetProps[] = [
            ...(
                decodedTokenData.roles !== 'AssociationAdmin' ? (
                    [
                        {
                            type: "filter-menu",
                            children: <PlayerFilter filterData={filterData} onFilterChange={handleFilterChange} />,
                            sx: {
                                order: 1
                            }
                        }
                    ] as WidgetProps[]
                ) : []
            ),

            {
                type: "search-input",
                placeholder: "Search",
                onChange: onSearchTextChange,
                responsive: {
                    xs: 12,
                    sm: "auto"
                },
                sx: {
                    order: {
                        xs: 4,
                        sm: 1
                    }
                }
            },
            {
                type: "button",
                label: t(responsiveString({ xs: "Import", sm: "Import" })),
                startIcon: <Upload />,
                onClick: () => setUploadModalOpen(true),
                sx: {
                    order: {
                        xs: 2,
                        sm: 3
                    }
                }
            },
            {
                type: "button",
                label: t(responsiveString({ xs: "Add", sm: "Add Player" })),
                startIcon: <Add />,
                onClick: () => setAddModalOpen(true),
                sx: {
                    order: {
                        xs: 2,
                        sm: 3
                    }
                }
            },
            ...(
                submitEnabled ? (
                    [
                        {
                            type: "button",
                            label: "Submit",
                            variant: 'contained',
                            onClick: () => setSubmitConfirmModal(true),
                            sx: {
                                order: {
                                    xs: 3,
                                    sm: 4
                                }
                            }
                        }
                    ] as WidgetProps[]
                ) : []
            )
        ];


        return baseConfig;
    }, [submitEnabled, filterData, decodedTokenData])



    const handleRefetch = () => {
        childRef.current?.refetchData({
            requestEventId: requestEventId,
            search: searchText,
            clubId: decodedTokenData.roles === 'ClubAdmin' ? decodedTokenData.ClubId : filterData?.clubId,
            associationId: decodedTokenData.roles === 'AssociationAdmin' ? decodedTokenData.AssociationId : filterData?.associationId,
            isSubmitted: false
        });
    };

    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedUser) return;
        const variables = {
            deletePlayerByIdId: selectedUser?.id,
        }

        try {
            const { data } = await deletePlayer({
                variables: variables
            });
            setSelectedUser(null);

            if (data.deletePlayerById.errors?.length > 0) {
                console.error('Error:', data.deletePlayerById.errors);
                enqueueSnackbar(data.deletePlayerById.errors[0], { variant: "error" });
            } else {

                childRef?.current?.handleDeleterowPagination()
                enqueueSnackbar(t("Player deleted successfully"), { variant: "success" });

            }
        } catch (error) {
            setSelectedUser(null);
            console.error('Mutation error:', error);
        }

    }

    const onAddUserModalClose = (submitted?: boolean) => {
        setAddModalOpen(false);
        setSelectedUser(null);
        if (submitted) {
            handleRefetch();
        }
    }

    const handleClose = () => {
        setUploadModalOpen(false);
        setSelectedFile(undefined);
        setFileName('');
    };


    return (
        <>
            {requestEventId && (
                <TablePageHoc
                    actions={ActionBarConfig}
                    columns={TableConfig}
                    ref={childRef}
                    query={QUERY}
                    params={{
                        requestEventId: requestEventId,
                        search: searchText,
                        clubId: decodedTokenData.roles === 'ClubAdmin' ? decodedTokenData.ClubId : filterData?.clubId,
                        associationId: decodedTokenData.roles === 'AssociationAdmin' ? decodedTokenData.AssociationId : filterData?.associationId,
                        isSubmitted: false
                    }}
                    onDataLoaded={(data) => {
                        if (data?.getPlayerList?.players?.length > 0 && !submitEnabled) {
                            setSubmitEnabled(true);
                        } else if ((!(data?.getPlayerList?.players?.length > 0)) && submitEnabled) {
                            setSubmitEnabled(false);
                        }
                    }}
                    sourceAccessor={(data) => data.getPlayerList.players}
                    countAccessor={(data) => data?.getPlayerList?.totalPlayers}
                    pagination={{
                        enable: true,
                        page: page,
                        onChange: (page) => {
                            setPage(page)
                        }
                    }}
                    nullComponent={

                        <Box sx={{ display: 'flex', flexDirection: 'column', 'justifyContent': 'center', alignItems: 'center', my: { xs: 30, lg: 50 } }}>
                            <Typography >{t("Currently there are no registered players.")}</Typography>
                            <Typography >{t("To start, click on the + Add player button to begin registering players.")}</Typography>
                        </Box>
                    }
                />

            )}
            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedUser ? "Edit Player" : "Add Player",
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        children: <AddPlayer player={selectedUser} requestId={requestEventId} onSave={() => onAddUserModalClose(true)} onClose={() => {
                            setAddModalOpen(false)
                            setSelectedUser(null)
                        }} />,
                        size: 'lg'
                    })
            }
            {
                getWidget(
                    {
                        type: "modal",
                        title: t('Upload File'),
                        modalOpen: uploadModalOpen,
                        onClose: () => setUploadModalOpen(false),
                        children: <FileUpload handleSubmit={handleFileSubmit} fileName={fileName} handleChange={(event: any) => handleFileUpload(event)} fileUpload={fileUpload} onClose={handleClose} />,
                        size: 'md'
                    })
            }
            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the player')} ${selectedUser?.playerName}? ${t('Once deleted, this action cannot be undone!')}`}
            />
            <ConfirmModal modalOpen={submitConfirmModal} onClose={() => setSubmitConfirmModal(false)} onConfirm={() => submitPlayers()} title={t('Are you sure?')} children={t('Do you want to submit the player list now? Once submitted, you will not be able to edit the player details.')} />
        </>
    )
}

export default RegisteredPlayersList