import './assets/i18n/config'
import './App.scss';
import VerificationEmailPage from './pages/guest/Verification-Page/VerificationEmail.page';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, createHttpLink, useMutation, DefaultOptions } from '@apollo/client';
import { setContext } from "@apollo/client/link/context"
import LoginPage from './pages/guest/Login/Login.page';
import AdminLayout from './pages/admin/admin.layout';
import DashBoard from './pages/admin/dashboard/dashboard';
import ForgotPasswordPage from './pages/guest/Forgot-password/forgotPassword.page';
import SetPasswordPage from './pages/guest/Set-Password/SetPassword';
import SuccessPage from './pages/guest/Success-Page/Success.page';
import UserListPage from './pages/admin/user-management/user-list';
import PlayersListPage from './pages/admin/players-management/players';
import AssocationListPage from './pages/admin/association-management/assocation';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { getTheme } from './theme/base-theme';
import GuestPage from './pages/guest/Guest-Page/GuestPage';
import { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import { updateAccessTokenAndLoginStatus } from './store/reducers/AuthSlice';
import { stopAppLoading } from './store/reducers/AppSlice';
import { SnackbarProvider } from 'notistack';
import { Box, CircularProgress, Paper } from '@mui/material';
import { FacebookCircularProgress } from './progress-bar';
import logo from './assets/images/logo.svg'
import { LanguageProvider } from './shared/provider/LanguageProvider';
import AssociationListPageTab from './pages/admin/associations-list-page/AssociationListPage';
import LayoutListPage from './pages/admin/layout-management/layoutlistpage';
import EventListPage from './pages/admin/event-management/event-list';
import EventListManagePage from './pages/admin/event-management/event-management';
import EventSlotListPlayersPage from './pages/admin/event-management/slot-playerlist-page';
import SubmiitedList from './pages/admin/players-management/submitted-list';
import PackageList from './pages/admin/package-management/package-list';
import CategoryDashboardPage from './pages/admin/category-management/category-dashboard';
import CustomerListPage from './pages/admin/customer-management/customer-list-page';
import SeasonList from './pages/admin/season-management/season-list';


const routes = [
  {
    path: "/",
    element: <GuestPage />,
    children: [
      {
        index: true,
        element: <Navigate to={"/login"} />
      },
      {
        path: "login",
        index: true,
        element: <LoginPage />,
      },
      {
        path: "reset-password/email",
        element: <ForgotPasswordPage />
      },
      {
        path: "reset-password/:key/:email/verify",
        element: <VerificationEmailPage />
      },
      {
        path: "reset-password/:key/set-password",
        element: <SetPasswordPage />
      },
      {
        path: "reset-password/success",
        element: <SuccessPage />
      }
    ]

  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={"/admin/dashboard"} />
      },
      {
        path: "dashboard",
        element: <DashBoard />
      },
      {
        path: "user-management",
        element: <UserListPage />
      },
      {
        path: "association-management",
        element: <AssocationListPage />
      },
      {
        path: "association-management/:clubId",
        element: <AssociationListPageTab />
      },
      {
        path: "players-management",
        element: <PlayersListPage />
      },
      {
        path: "players",
        element: <SubmiitedList />
      },
      {
        path: "layout-management",
        element: <LayoutListPage />
      },
      {
        path: "event-management",
        element: <EventListManagePage />
      },
      {
        path: "package-management",
        element: <PackageList />
      },
      {
        path: "event-management/detail",
        element: <EventSlotListPlayersPage />
      },
      {
        path: "players-management/:requestEventIdParam",
        element: <PlayersListPage />
      },
      {
        path: "category-management",
        element: <CategoryDashboardPage />
      },
      {
        path: "customer-management",
        element: <CustomerListPage />
      },
      {
        path: "season-management",
        element: <SeasonList />
      }
    ]
  }
];

function renderRoutes(routeConfig: any[]) {
  return (
    <>
      {routeConfig.map(({ path, element, children, index }: any) => (
        <Route key={index + "_" + path} path={path && path} element={element} index={index && index}>
          {children && renderRoutes(children)}
        </Route>
      ))}
    </>
  );
}


const REFRESH_TOKEN_QUERY = gql`
mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    errors
    success
    token {
      accessToken
      refreshToken
    }
  }
}

`;

function AppWrapper() {

  const [cookies] = useCookies(['token']);


  const httpLink = createHttpLink({
    // uri: 'https://evaluva-api.bixbytessolutions.com/',
    uri: 'https://evalua-live-api.bixbytessolutions.com/',
    // uri: 'http://localhost:6767/'
  });

  const authSetup = setContext((_, { headers }: any) => {

    return {
      headers: {
        ...headers,
        authorization: cookies.token ? `Bearer ${cookies.token}` : ''
      }
    }
  })
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const client = new ApolloClient({
    link: authSetup.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });


  return (
    <ApolloProvider client={client}>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </ApolloProvider>
  )
}


function App() {
  const dispatch = useDispatch();
  const themeSetting = useSelector((state: any) => state.app.theme);
  const appLoading = useSelector((state: any) => state.app.applicationLoading);
  const pageLoading = useSelector((state: any) => state.app.pageLoading);
  const [cookies, setCookie] = useCookies(['token', 'refreshToken']);
  const [refreshTokenApi, { data, loading, error }] = useMutation(REFRESH_TOKEN_QUERY);
  const timer = useRef<any>(null);



  useEffect(() => {
    checkToken();
  }, [])

  useEffect(() => {


    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (cookies.token) {
      startRefreshTokenTimer();
    }
  }, [cookies.token])

  const decodeToken = (token: string) => {
    const jwtToken: any = jwt_decode(token);
    const expires = jwtToken && new Date(jwtToken.exp * 1000);
    let timeout = expires && expires.getTime() - Date.now() - (60 * 1000);
    timeout = timeout - 50000;
    if (timeout < 0) timeout = 0;
    return timeout;
  }

  const checkToken = async () => {
    try {
      const token = cookies.token;
      const rToken = cookies.refreshToken;
      const timeout = decodeToken(token);
      const rTimeout = decodeToken(rToken);
      if (timeout > 0) {
        var decoded = jwt_decode(token);
        dispatch(updateAccessTokenAndLoginStatus({ token: token, isLoggedIn: true, decodedTokenData: decoded }))
        dispatch(stopAppLoading())
      } else if (rTimeout > 0) {
        const res = await refreshToken();
        dispatch(stopAppLoading())
      }
    } catch (e) {
      dispatch(updateAccessTokenAndLoginStatus({ token: null, isLoggedIn: false }))
      dispatch(stopAppLoading())
    }
  }


  const startRefreshTokenTimer = () => {
    try {
      const token = cookies.token;
      if (!token) return;

      const rToken = cookies.refreshToken;
      const timeout = decodeToken(token);
      const rTimeout = decodeToken(rToken);


      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        if (rTimeout > 0) {
          refreshToken();
        }
      }, timeout);
    } catch (e) {

    }
  }

  const refreshToken = async () => {
    let res = await refreshTokenApi({ variables: { refreshToken: cookies.refreshToken } })

    if (!res.data.refreshToken.success) {
      let err = res.data.refreshToken.errors
      dispatch(updateAccessTokenAndLoginStatus({ token: null, isLoggedIn: false }))
    } else {
      var decoded = jwt_decode(res.data.refreshToken.token.accessToken);
      dispatch(updateAccessTokenAndLoginStatus({ token: res.data.refreshToken.token.accessToken, isLoggedIn: true, decodedTokenData: decoded }))
      setCookie('token', res.data.refreshToken.token.accessToken, { path: '/' })
      setCookie('refreshToken', res.data.refreshToken.token.refreshToken, { path: '/' })
    }
  }



  return (
    <ThemeProvider theme={getTheme(themeSetting)}>
      <SnackbarProvider autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{
        fontSize: '1.5rem'
      }}>
        {
          appLoading ? <>
          </> : (
            <BrowserRouter>
              <Routes>
                {
                  renderRoutes(routes)
                }
              </Routes>
            </BrowserRouter>
          )
        }
      </SnackbarProvider>

      {
        appLoading || pageLoading && (
          <Paper className="application-loading-wrapper">
            <Box sx={{ width: 300 }}>
              <img src={logo} style={{ width: '100%' }} className="application-loading-logo" />
            </Box>
            <FacebookCircularProgress />
          </Paper>
        )
      }
    </ThemeProvider>
  );
}

export default AppWrapper;
