import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useTranslation } from 'react-i18next'
import "./stepper.module.scss";
import SubmittedEditPlayersList from './event-available-players';
import PreviewEditSubmittedPlayersList from './event-preview-list';




export default function HorizontalNonLinearStepper({ onSave, onClose, slotId, eventId, onAddClose, editMode, requestEventId }: any) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [playerData, setPlayerData] = React.useState<any>(null);
  
    const { t } = useTranslation();

    const steps = [t('Add Players'), t('Preview')];


    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        // setLatestClubId(club.id)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);

    };

    const handleEventPlayers = (data: any) => {
        setPlayerData(data);
    }





    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            {t(label)}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>

                {
                    activeStep === 0 && <SubmittedEditPlayersList handleNext={handleNext} handleEventPlayers={handleEventPlayers} onClose={onClose} editMode={editMode} selectedPlayers={playerData} requestEventId={requestEventId} slotId={slotId} eventId={eventId}/>
                }
                {
                    activeStep === 1 && <PreviewEditSubmittedPlayersList handleNext={handleNext} data={playerData} handleEventPlayers={handleEventPlayers} onClose={onClose} slotId={slotId} eventId={eventId} onAddClose={onAddClose} editMode={editMode} />
                }

            </div>
        </Box>
    );
}