import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, TextField } from '@mui/material'
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { ModalProps, ModalPropsBase, TableColumn } from '../../../../src/shared/types/widget-types';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import { splitCamelCase } from '../../../shared/utils/util-functions';
import TablePageHoc from "../../../../src/shared/hoc/table-list/table-list-hoc"



const GET_ASSOCIATIONS_QUERY = gql`query GetAllAssociations($clubId: Int, $search: String, $page: Int, $pageSize: Int) {
    getAllAssociations(clubId: $clubId, search: $search, page: $page, pageSize: $pageSize) {
      associations {
        associationName
        id
      }
      errors
      success
      totalAssociations
    }
  }`

const QUERY = `query GetPlayerEvents($search: String, $page: Int, $pageSize: Int, $playerId: Int) {
    getPlayerEvents(search: $search, page: $page, pageSize: $pageSize, playerId: $playerId) {
      errors
      success
      totalEventSlots
      eventslot {
        Event {
          title
        }
        EventSlot {
          title
          location
        }
      }
    }
  }`


const CustomDialog = (props: any) => {
    return <Dialog
        {...props}
        className={
            clsx({
                'sizeSmall': props.size == 'sm',
                'sizeLarge': props.size == 'lg',
                'sizeMedium': props.size == 'md',
                'sizeExtraLarge': props.size == 'xl'
            })
        }
    />
}

export interface ClubDetailModalProps extends ModalPropsBase {
    // onConfirm: () => void;
    // children: string;
}

type ChildRef = {
    refetchData: (data: any) => void;
};



function PlayerDetailModal({ actions, size, modalOpen, onClose, detail }: ClubDetailModalProps) {

    const [clubData, setClubsData] = useState<any>(null);
    const [associationData, setAssociationData] = useState<any>(null);
    const [usersData, setUsersData] = useState<any>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [page, setPage] = React.useState(1);
    const { refetch: associationFetch } = useQuery(GET_ASSOCIATIONS_QUERY, { skip: true });
    // const { refetch: usersFetch } = useQuery(GET_USERS_QUERY, { skip: true });
    const navigate = useNavigate();

    const { firstName, lastName, dateOfBirth, id, playerAddress, city, nationality, Association } = detail


    useEffect(() => {
        if (decodedTokenData.roles == 'SuperAdmin' && modalOpen) {
            // getClubData();
            getAssociationData();
            getUsersData();
        }
    }, [decodedTokenData, modalOpen])



    const getAssociationData = async () => {

        const { data } = await associationFetch({
            search: "",
            page: null,
            pageSize: null,
            clubId: parseInt(id)
        });
        setAssociationData(data);
        return data;
    }



    const getUsersData = async () => {

        const { data } = await associationFetch({
            search: "",
            page: null,
            pageSize: null,
            clubId: parseInt(id)
        });
        setAssociationData(data);
        return data;
    }


    const TableConfig: TableColumn[] = [

        {
            label: "Event name",
            align: "left",
            accessor: (row: any) => row?.Event?.title
        },
        {
            label: "Slot",
            align: "left",
            accessor: (row: any) => row?.EventSlot?.title
        },

        {
            label: "Location",
            align: "left",
            accessor: (row: any) => row?.EventSlot?.location
        }
    ]

    const onCloseClick = () => {
        onClose?.()
    }

    const onCloseButtonClick = () => {
        onClose?.()
        navigate(`/admin/association-management/${id}`);
    }



    const onConfirmClick = () => {
        // onConfirm?.();
        onClose?.();

    }



    const { t } = useTranslation();

    return (
        <>
            <CustomDialog
                open={modalOpen ?? false}
                onClose={() => onCloseClick()}
                size='xl'
                sx={{ border: 0 }}

            >
                <DialogTitle variant='h6' color={"primary"}>
                    {t("Player Details")}
                </DialogTitle>
                {/* <Divider/> */}
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12} sm={4} md={4}>
                            <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                                {t("Player Name")}
                            </DialogContentText>
                            <DialogContentText sx={{ "fontSize": "16px", "marginBottom": "10px", 'fontWeight': "500" }}>
                                {firstName}
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} >
                            <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                                {t("Association")}
                            </DialogContentText>
                            <DialogContentText sx={{ "fontSize": "16px", "marginBottom": "10px", 'fontWeight': "500" }}>
                                {Association?.associationName}
                            </DialogContentText>
                        </Grid>


                        <Grid item xs={12} sm={4} md={4} >
                            <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                                {t("City")}
                            </DialogContentText>
                            <DialogContentText sx={{ "fontSize": "16px", "marginBottom": "10px", 'fontWeight': "500" }}>
                                {city}
                            </DialogContentText>
                        </Grid>

                    </Grid>

                    <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                        {t("List Of Event")}
                    </DialogContentText>
                    <div style={{ 'marginTop': '20px' }}>
                        <TablePageHoc
                            columns={TableConfig}
                            query={QUERY}
                            params={{
                                // clubId:filterData?.clubId,
                                search: null,
                                playerId: parseInt(id),

                            }}
                            sourceAccessor={(data: any) => data.getPlayerEvents.eventslot}
                            countAccessor={(data: any) => data.getPlayerEvents.totalEventSlots}
                            pagination={{
                                enable: true,
                                page: page,
                                limit: 5,
                                onChange: (page: any) => {
                                    setPage(page)
                                }
                            }} /></div>


                </DialogContent>


            </CustomDialog>
        </>
    )
}

export default PlayerDetailModal