import React from 'react'
import { MuiOtpInput } from 'mui-one-time-password-input'
import style from './otp.module.scss'
import { FormHelperText } from '@mui/material'

const OtpComponent = ({value, onChange, error, helperText}: any) => {


  const validateCharacter = (character: string, index: number) => {
    let value = parseInt(character);
    if (value >= 0 && value <= 9) {
      return true;
    }
    return false;
  }



  const overrideOnChange = (event: any) => {
    
    if (onChange) {
        onChange({
          target: {
            value: event
          }
        })
    }
}



  return (
    <div className={style.otp_wrapper}>
      <MuiOtpInput 
          value={value} 
          onChange={overrideOnChange}
          length={6} 
          validateChar={validateCharacter}
          TextFieldsProps= {
            {
              variant: 'outlined',
              size: 'small',
              className: style.otp_input,
              error: error ?? false
            }
          }
          autoFocus
          gap={10} />
      <FormHelperText error={error}>{helperText ?? ' '}</FormHelperText>
    </div>
  )
}


export default OtpComponent;
