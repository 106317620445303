import React, { useState, useRef, useContext, useEffect } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { useMutation, gql, useQuery } from '@apollo/client';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { MenuItem } from '@mui/material';
import { getWidget } from '../../../shared/widgets';
import AddClub from './add-club';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString } from '../../../shared/utils/util-functions';
import ClubDetailModal from '../../../shared/hoc/modal/club-detail-modal';
import HorizontalNonLinearStepper from '../../../shared/widgets/stepper-widget/Stepper';
import { useLocation } from 'react-router';

const DELETE_USER_QUERY = gql`mutation Mutation($deleteOneClubId: ID!) {
    deleteOneClub(id: $deleteOneClubId) {
      success
      errors
      club {
        clubName
      }
    }
  }`



type ChildRef = {
  refetchData: (data: any) => void;
  handleDeleterowPagination: () => void
};



function ClubListPage() {
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [selectedUser, setSelectedUser]: any[] = React.useState(null);
  const [editMode, setEditMode]: any = React.useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [clubDetailInfo, setClubDetailInfo] = React.useState({});
  const [ClubDetail, setClubDetailModal] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = React.useState(1);
  const childRef = useRef<ChildRef | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [latestClubId, setLatestClubId] = useState<any>(null);
 

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [deleteClub] = useMutation(DELETE_USER_QUERY);

  const pageMeta = useContext(PageMetaContext);

  useEffect(() => {
    
    if (pageMeta) {
      const { setPageName, setBreadcrumbs } = pageMeta;
      setPageName("Clubs");
    }
  }, [pageMeta]);




  const handleRefetch = () => {
    childRef.current?.refetchData({
      search: searchText ?? ""
    });
  };




  const deleteRow = async () => {
    setDeleteConfirmModal(false);
    if (!selectedUser) return;
    const variables = {
      deleteOneClubId: selectedUser?.id,
    }

    try {
      const { data } = await deleteClub({
        variables: variables
      });
      setSelectedUser(null);

      if (data.deleteOneClub.errors?.length > 0) {
        console.error('Error:', data.deleteOneClub.errors);
        enqueueSnackbar(data.deleteOneClub.errors[0], { variant: "error" });
      } else {
      
        childRef?.current?.handleDeleterowPagination()
        enqueueSnackbar(t("Club deleted successfully"), { variant: "success" });
        
      }
    } catch (error) {
      setSelectedUser(null);
      console.error('Mutation error:', error);
    }

  }

  const TableConfigClubs: TableColumn[] = [
    {
      label: "Action",
      align: "left",
      width: 130,
      accessor: (row: any) => {
        return <>
          <ActionMenu
            button={{
              type: "icon-button",
              icon: <MoreVertOutlined />,
              size: 'small',
              color: 'primary',
              sx: { color: 'white' }
              
            }}
            children={
              [
                <MenuItem onClick={(event) => {
                  setSelectedUser(row);
                  setAddModalOpen(true)
                  event.stopPropagation();
                  setEditMode(true)
                }}>{t('Edit')}</MenuItem>,
                <MenuItem onClick={(event) => {
                  setSelectedUser(row);
                  setDeleteConfirmModal(true)
                  event.stopPropagation();
                }}>{t('Delete')}</MenuItem>
              ]
            }
          />
        </>
      }
    }
    ,
    {
      label: "Club Name",
      align: "left",
      accessor: (row: any) => row.clubName
    },
    {
      label: "Club Address",
      align: "left",
      accessor: (row: any) => row.clubAddress
    },
 

  ]



  const onSearchTextChange = (event: any) => {
    if (searchText !== event.target.value) {
      setPage(1)
    }
    setSearchText(event.target.value)
  }

  useUpdateEffect(() => {
    handleRefetch();
  }, [searchText], 300)






  const QUERY = `query Query($search: String, $page: Int, $pageSize: Int) {
  getAllClubs(search: $search, page: $page, pageSize: $pageSize) {
    clubs {
      clubAddress
      clubName
      id
    }
    errors
    success
    totalClubs
  }
}
      `




  const ActionBarConfig: WidgetProps[] = [

    {
      type: "search-input",
      placeholder: "Search",
      onChange: onSearchTextChange,
      responsive: {
        xs: 12,
        sm: "auto"
      },
      sx: {
        order: {
          xs: 2,
          sm: 1
        }
      }
    },
    {
      type: "button",
      label: t(responsiveString({ xs: "Add", sm: "Add Club" })),
      startIcon: <Add />,
      onClick: () => {
        setAddModalOpen(true)
        setEditMode(false)
        setSelectedUser(null)
      },
      sx: {
        order: {
          xs: 1,
          sm: 2
        }
      }
    },
  ]




  const onAddUserModalClose = () => {
    setAddModalOpen(false);
  
    handleRefetch()
  }







  return (
    <div>
      <TablePageHoc actions={ActionBarConfig} columns={TableConfigClubs} ref={childRef} query={QUERY} params={{
        search: searchText
      }}
        sourceAccessor={(data) => data.getAllClubs.clubs} countAccessor={(data) => data.getAllClubs.totalClubs}
        pagination={{
          enable: true,
          page: page,
          onChange: (page) => {
            setPage(page)
          }
        }}
        onRowClick={(row) => {
          setClubDetailInfo(row)
          setClubDetailModal(true)
        }}
        detailPointer="pointer" 
      />
      {
        getWidget(
          {
            type: "modal",
            title: selectedUser ? "Edit Club" : "Add Club",
            modalOpen: addModalOpen,
            onClose: () => onAddUserModalClose(),
            children: selectedUser ? <AddClub club={selectedUser} editMode={editMode} onSave={() => onAddUserModalClose()} onClose={() => {
              setAddModalOpen(false)
            }} /> : <HorizontalNonLinearStepper latestClubId={latestClubId} club={selectedUser} onSave={() => onAddUserModalClose()} onClose={() => {
              setAddModalOpen(false)
              handleRefetch()
            }} />,
          })
      }
      <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the club')} ${selectedUser?.clubName}? ${t('Once deleted, this action cannot be undone!')}`} />
      <ClubDetailModal modalOpen={ClubDetail} onClose={() => setClubDetailModal(false)} detail={clubDetailInfo} />
    </div>
  )
}

export default ClubListPage