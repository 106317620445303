import React, { useContext, useEffect, useMemo, useRef } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from "react";
import { gql } from '@apollo/client';
import { Box, Button, ButtonGroup, Divider, Grid, MenuItem } from '@mui/material';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import { useCookies } from 'react-cookie';
import { useParams, useSearchParams } from 'react-router-dom';



const GET_EVENT_DETAILS = gql`
query GetEventDetail($eventId: ID) {
    getEventDetail(eventId: $eventId) {
      success
      errors
      event {
        title
        id
        EventLayout {
          title
          id
          layoutOptions {
            optionTitle
            id
            subOptions {
              optionTitle
              id
            }
          }
        }
      }
    }
  }`;



type ChildRef = {
    refetchData: (data: any) => void;
};



function SubmittedSlotPlayersList({  handleEventUsers, onClose, slotId , eventId, event }: any) {

    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState(null);
    const childRef = useRef<ChildRef | null>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [eventDetails, setEventDetails] = useState<any>(null);
    const [eventPlayer, setEventPlayer] = useState<any>([]);
    const pageMeta = useContext(PageMetaContext);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { refetch: getEventDetailsFetch } = useQuery(GET_EVENT_DETAILS, { skip: true });



    const { requestEventIdParam } = useParams();

    const extractYear = (timestamp: any) => {
        const date = new Date(parseInt(timestamp));
        return date.getFullYear();
    };

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Submitted Players");
        }
    }, [pageMeta]);

    useEffect(() => {

        getEventDetails();
    }, [])





    const getEventDetails = async () => {
        const { data } = await getEventDetailsFetch({
            eventId: eventId
        });
        setEventDetails(data.getEventDetail.event);
    }





    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText ?? "",
            slotId: slotId
        });
    };

    const getPlayerData = () => {
        handleEventUsers(eventPlayer)
    }



    const TableConfig: TableColumn[] =
        [   
            {
                label: "Jersey Number",
                align: "center",
                accessor: (row: any) => row?.slotPlayerJerseyNumber
            },

            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.firstName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Last Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Date Of Birth",
                align: "left",
                accessor: (row: any) => row.dateOfBirth
            },



            {
                label: "Association",
                align: "left",
                accessor: (row: any) => row?.associationName
            },

            {
                label: "Goal Keeper",
                align: "left",
                accessor: (row: any) => t(row.goalKeeper)
            },
            {
                label: "Summary",
                align: "left",
                accessor: (row: any) => {
                    if (eventDetails) {

                        const evaluationSummary: any = {};
                        row.optionCounts.map((option: any) => (evaluationSummary[option.optionId] = option.count))
                        let eventLayoutOptions = [...eventDetails.EventLayout.layoutOptions];
                        eventLayoutOptions.sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id));
                        const buttons = eventLayoutOptions.map((option: any) => {
                            if (option.subOptions.length <= 0) {
                                return <Button type='button' variant='text' disabled style={{ 'color': '#D6D0D0' }} size='small'>{`${option.optionTitle} : `} {` ${evaluationSummary[option.id] ?? 0}`}</Button>;
                            } else {
                                return <ButtonGroup variant="outlined" aria-label="Basic button group" size='small'>
                                    <Button type='button' disabled variant='text' color="primary" size='small'>{`${option.optionTitle} : `}</Button>
                                    {
                                        option.subOptions.map((so: any) => {
                                            return <Button type='button' variant='text' disabled style={{ 'color': '#D6D0D0' }} size='small'>{`${so.optionTitle} : `}{evaluationSummary[so.id] ?? 0}</Button>
                                        })
                                    }
                                </ButtonGroup>
                            }
                        })
                        if (buttons.length)
                            return <>{buttons}</>

                    }
                    return <></>;
                }
            }
        ];


    const QUERY = `query GetAllSlotPlayers($search: String, $page: Int, $pageSize: Int, $slotId: Int, $status: Boolean) {
            getAllSlotPlayers(search: $search, page: $page, pageSize: $pageSize, slotId: $slotId , status: $status) {
              errors
              success
              totalEventPlayers
              players {
                firstName
                slotPlayerId
                id
                lastName
                nationality
                playerAddress
                postCode
                relationship
                strongFoot
                telephone
                goalKeeper
                dateOfBirth
                associationName
                slotPlayerJerseyNumber
                optionCounts {
                    count
                    optionId
                }
              }
            }
          }
          `


    return (
        <div>
            <TablePageHoc columns={TableConfig as TableColumn[]} ref={childRef} query={QUERY}

                params={{
                    search: searchText,
                    slotId: slotId
                }}
                sourceAccessor={(data) => data?.getAllSlotPlayers?.players}
                countAccessor={(data) => data?.getAllSlotPlayers?.totalEventPlayers}
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }} />



            <Divider sx={{ mx: -24, mb: 20, mt: 20 }} />
            <Grid container justifyContent={"flex-end"}>
                <Grid item xs={"auto"}>
                    <Button type="button" variant="text" color="secondary" onClick={onClose}>{t('Close')}</Button>
                </Grid>

            </Grid>
        </div>
    )
}

export default SubmittedSlotPlayersList