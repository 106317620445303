import { useState, useEffect, useRef } from 'react';

export function useHasWrapped(ref1:any) {
    const [hasWrapped, setHasWrapped] = useState(false);
    const ref2:any = useRef(null);
    
    useEffect(() => {
        const checkWrap = () => {
            if (ref1.current && ref2.current && ref2.current.offsetTop > ref1.current.offsetTop) {
                setHasWrapped(true);
            } else {
                setHasWrapped(false);
            }
        };

        checkWrap();
        window.addEventListener('resize', checkWrap);

        return () => {
            window.removeEventListener('resize', checkWrap);
        };
    }, []);
    
    return [ref2, hasWrapped];
}