import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import ActionbarWidget from '../../widgets/actionbar/actionbar.component'
import DataTable from '../../widgets/table/table.component'
import PaginationRounded from '../../widgets/pagination/pagination.component'
import { TableColumn, WidgetProps } from '../../types/widget-types'
import { gql, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { stopPageLoading } from '../../../store/reducers/AppSlice'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface TablePageHocPaginationBase {
    enable: boolean;
    page?: number;
    limit?: number;
    onChange?: (page: number) => void;
}
interface KeyValuePair {
    [key: string]: any;
}
interface TablePageHocPropsBase {
    query: string;
    sourceAccessor: (data: any) => any[];
    countAccessor: (data: any) => number;
    actions?: WidgetProps[];
    columns: TableColumn[];
    pagination?: TablePageHocPaginationBase;
    params?: KeyValuePair;
    onDataLoaded?: (data: any) => void;
    nullComponent?: React.ReactNode;
    detailPointer?: any;
    onRowClick?: (...args: any) => void;
    tableWrapperStyles?:any;
}
const perPageDefaultLimit = 10;
function TablePageHoc({ query, params: param2, actions, columns, tableWrapperStyles, pagination, sourceAccessor, countAccessor, onDataLoaded, nullComponent, onRowClick, detailPointer }: TablePageHocPropsBase, ref: any) {
    const [page, setPage] = React.useState(pagination?.page ?? 1);
    const [dataLoading, setDataLoading] = useState(true);
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState<any>(null);
    const paramRef = useRef<any>({});
    const { t } = useTranslation();

    

    useEffect(() => {

        paramRef.current = {
            ...(param2 ? param2 : {}),
            ...(
                pagination && pagination.enable ? {
                    page: pagination?.page ?? page,
                    pageSize: pagination.limit ?? perPageDefaultLimit
                } : {}
            )
        };
    }, [pagination, page, param2])

    const { refetch: fetchTableData } = useQuery(gql`${query}`, {
        skip: true
    });


    useEffect(() => {

        const paramsMerged = {
            ...(param2 ? param2 : {}),
            ...(
                pagination && pagination.enable ? {
                    page: pagination?.page ?? page,
                    pageSize: pagination.limit ?? perPageDefaultLimit
                } : {}
            )
        };

        setDataLoading(true);
        getTableData(paramsMerged)
    }, [page])

    useImperativeHandle(ref, () => ({
        refetchData: (data: any) => {
            const updatedParams = {
                ...(paramRef.current),
                ...(data ? data : {}),
            };

            setDataLoading(true);
            getTableData(updatedParams)

        },
        handleDeleterowPagination: () => {
            const itemCount = sourceAccessor(tableData).length;
            if (itemCount === 1 && page > 1) {
                const newPage = page - 1;
                setPage(newPage);
                pagination?.onChange && pagination?.onChange(newPage)
            } else {
                const updatedParams = {
                    ...(paramRef.current)
                };

                setDataLoading(true);
                getTableData(updatedParams)
            }
        }
    }), [param2, pagination]);





    const actionBar = useMemo(() => {
        return actions ? <ActionbarWidget actions={actions} /> : null;
    }, [actions]);

    const getTableData = async (params: any) => {
        const { data } = await fetchTableData(params);
        setTableData(data);
        onDataLoaded?.(data)
        dispatch(stopPageLoading())
        setDataLoading(false);
    }


    return (
        <>
            {actionBar}
            <div style={tableWrapperStyles ?? {}}>
                {
                    tableData && (
                        <>
                            <DataTable dataSource={sourceAccessor(tableData) ?? []} columns={columns} onRowClick={onRowClick} detailPointer={detailPointer} />

                            {
                                pagination && pagination.enable && countAccessor(tableData) > (pagination.limit ?? perPageDefaultLimit) &&
                                <PaginationRounded totalItems={countAccessor(tableData) ?? 0}
                                    itemsPerPage={pagination.limit ?? perPageDefaultLimit}
                                    currentPage={pagination?.page ?? page}
                                    onChange={(page) => {
                                        setPage(page)
                                        pagination.onChange && pagination.onChange(page)

                                    }} />
                            }
                        </>
                    )
                }
                {(
                    !dataLoading && countAccessor(tableData) == 0 && (
                        nullComponent ?? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', 'justifyContent': 'center', alignItems: 'center', my: { xs: 30, lg: 50 } }}>
                                <Typography >{t("Currently, there is no data.")}</Typography>
                                <Typography ></Typography>
                            </Box>
                        )
                    )
                )
                }
            </div>
        </>
    )
}

export default forwardRef(TablePageHoc);