import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, MenuItem, TextField } from '@mui/material'
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux';
import { TableColumn } from '../../../shared/types/widget-types';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { MoreVertOutlined } from '@mui/icons-material';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { enqueueSnackbar } from 'notistack';
import AddUser from './add-customer';
import { getWidget } from '../../../shared/widgets';



const DELETE_USER_QUERY = gql`mutation DeleteOneUser($deleteOneUserId: ID!) {
    deleteOneUser(id: $deleteOneUserId) {
      success
      errors
      user {
        firstName
      }
    }
  }`

const SEND_EMAIL_QUERY = gql`mutation Mutation($userId: Int) {
    inviteCustomer(userId: $userId) {
      success
      status
      errors
    }
  }`

const QUERY = `query GetAllCustomers($role: String, $pageSize: Int, $page: Int, $search: String, $associationId: Int) {
  getAllCustomers(role: $role, pageSize: $pageSize, page: $page, search: $search, associationId: $associationId) {
    errors
    success
    totalUsers
    users {
      role
      relation
      lastName
      language
      id
      gender
      firstName
      dateOfBirth
      contactNumber
      designation
      email
      Association {
        associationName
      }
    }
  }
}`


const GET_USERS_QUERY = gql`query GetAllCustomers($role: String, $pageSize: Int, $page: Int, $search: String, $associationId: Int) {
  getAllCustomers(role: $role, pageSize: $pageSize, page: $page, search: $search, associationId: $associationId) {
    errors
    success
    totalUsers
    users {
      role
      relation
      lastName
      language
      id
      gender
      firstName
      dateOfBirth
      contactNumber
      designation
      email
      Association {
        associationName
      }
    }
  }
}`


const CustomDialog = (props: any) => {
    return <Dialog
        {...props}
        className={
            clsx({
                'sizeSmall': props.size == 'sm',
                'sizeLarge': props.size == 'lg',
                'sizeMedium': props.size == 'md',
                'sizeExtraLarge': props.size == 'xl'
            })
        }
    />
}

type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void;
};




function CustomerDetailModal({ modalOpen, onClose, detail }: any) {

    const [selectedUser, setSelectedUser]: any = React.useState(null);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [customer, setCustomer]: any = React.useState(null);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const { refetch: usersFetch } = useQuery(GET_USERS_QUERY, { skip: true });
    const [deleteUser] = useMutation(DELETE_USER_QUERY);
    const dispatch = useDispatch();
    const { Association, id, Package, endDate, startDate } = detail;
    const childRef = useRef<ChildRef | null>(null);
    const [sendInviteEmai] = useMutation(SEND_EMAIL_QUERY);
    const [emailConfirmModal, setEmailConfirmModal] = React.useState(false);



    const { t } = useTranslation()
    useEffect(() => {
        getCustomerData()
    }, [detail])

    const handleRefetch = () => {

        childRef.current?.refetchData({
        });
    };


    const getCustomerData = async () => {
        const { data } = await usersFetch({
            search: "",
            page: null,
            pageSize: null,
            associationId: parseInt(id)
        });
        setCustomer(data?.getAllCustomers?.users);
        return data;
    }

    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedUser) return;
        const variables = {
            deleteOneUserId: selectedUser?.id
        }

        try {
            const { data } = await deleteUser({
                variables: variables
            });
            setSelectedUser(null);

            if (data.deleteOneUser?.errors?.length > 0) {
                enqueueSnackbar(data.deleteOneUser.errors[0], { variant: "error" });
            } else {
                childRef?.current?.handleDeleterowPagination()
                handleRefetch();
                enqueueSnackbar(t("User deleted successfully"), { variant: "success" });

            }
        } catch (error) {
            setSelectedUser(null);
            console.error('Mutation error:', error);
        }
    }



    const sendEmail = async () => {
        setDeleteConfirmModal(false);
        if (!selectedUser) return;
        const variables = {
            userId: parseInt(selectedUser?.id)
        }

        try {
            const { data } = await sendInviteEmai({
                variables: variables
            });
            setSelectedUser(null);

            if (data.inviteCustomer?.errors?.length > 0) {
                enqueueSnackbar(data.inviteCustomer.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(t("Invite sent successfully"), { variant: "success" });
            }
        } catch (error) {
            setSelectedUser(null);
            console.error('Mutation error:', error);
        }

    }

    const TableConfig: TableColumn[] = [
        {
            label: "Action",
            align: "left",
            width: 130,
            accessor: (row: any) => {

                return <>
                    <ActionMenu
                        button={{
                            type: "icon-button",
                            icon: <MoreVertOutlined />,
                            size: 'small',
                            color: 'primary',
                            sx: { color: 'white' }
                        }}
                        children={
                            [
                                <MenuItem onClick={(event) => {
                                    setSelectedUser(row);
                                    setAddModalOpen(true)
                                    event.stopPropagation()
                                }}>{t('Edit')}</MenuItem>,
                                <MenuItem onClick={(event) => {
                                    setSelectedUser(row);
                                    setDeleteConfirmModal(true)
                                    event.stopPropagation();
                                }}>{t('Delete')}</MenuItem>,
                                <MenuItem onClick={() => {
                                    setSelectedUser(row);
                                    setEmailConfirmModal(true)
                                }}>{t('Invite')}</MenuItem>,

                            ]
                        }
                    />
                </>
            }
        },

        {
            label: "First Name",
            align: "left",
            accessor: (row: any) => <EllipsisBox content={row?.firstName} minWidth={250} lineClamp={2} />
        },
        {
            label: "Last Name",
            align: "left",
            accessor: (row: any) => <EllipsisBox content={row?.lastName} minWidth={250} lineClamp={2} />
        },
        {
            label: "Email",
            align: "left",
            accessor: (row: any) => row?.email
        },
        {
            label: "Contact Number",
            align: "left",
            accessor: (row: any) => row?.contactNumber
        },
        {
            label: "Role",
            align: "left",
            accessor: (row: any) => row?.role
        },
        {
            label: "Date Of Birth",
            align: "left",
            accessor: (row: any) => row?.dateOfBirth
        },
        {
            label: "Language",
            align: "left",
            accessor: (row: any) => row?.language
        },
        {
            label: "Relation",
            align: "left",
            accessor: (row: any) => t(row?.relation)
        },
    ]

    const onCloseClick = () => {
        onClose?.()
    }

    const onAddUser = () => {
        setAddModalOpen(true);
    }

    const onAddUserModalClose = () => {
        setAddModalOpen(false);
        setSelectedUser(null)
        handleRefetch();
    }


    return (
        <>
            <CustomDialog
                open={modalOpen ?? false}
                onClose={() => onCloseClick()}
                size='xl'
                sx={{ border: 0 }}

            >
                <DialogTitle variant='h6' color={"primary"}>
                    {t("View Customer")}
                </DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12} sm={3} md={3}>
                            <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                                {t("Customer Name")}
                            </DialogContentText>
                            <DialogContentText sx={{ "fontSize": "16px", "marginBottom": "10px", "fontWeight": "500" }}>
                                {Association?.associationName}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                                {t("Package")}
                            </DialogContentText>
                            <DialogContentText sx={{ "fontSize": "16px", "marginBottom": "10px", 'fontWeight': "500" }}>
                                {Package?.packageName}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                                {t("Package Expiry")}
                            </DialogContentText>
                            <DialogContentText sx={{ "fontSize": "16px", "marginBottom": "10px", 'fontWeight': "500" }}>
                                {endDate}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} sx={{ 'margin': 'auto' }}>
                            <DialogContentText>
                                <Button onClick={onAddUser} variant='contained' autoFocus>
                                    {t('Add admin')}
                                </Button>
                            </DialogContentText>
                        </Grid>
                    </Grid>

                    <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868", "marginTop": "10px" }}>
                        {t("Contact Person List")}
                    </DialogContentText>

                    <div style={{ 'marginTop': '20px' }}>
                        <TablePageHoc
                            columns={TableConfig}
                            ref={childRef}
                            query={QUERY}
                            params={{
                                associationId: parseInt(Association?.id),
                                role: 'Admin'
                            }}
                            sourceAccessor={(data: any) => data.getAllCustomers.users}
                            countAccessor={(data: any) => data.getAllCustomers.totalUsers}
                            pagination={{
                                enable: true,
                                page: page,
                                limit: 5,
                                onChange: (page: any) => {
                                    setPage(page)
                                }
                            }} /></div>
                </DialogContent>
                {
                    getWidget(
                        {
                            type: "modal",
                            title: selectedUser ? "Edit Customer" : "Add Customer",
                            size: 'md',
                            modalOpen: addModalOpen,
                            onClose: () => onAddUserModalClose(),
                            children: <AddUser users={selectedUser} latestAssociationId={selectedUser ? selectedUser?.id : Association?.id} onSave={() => onAddUserModalClose()} onClose={() => onAddUserModalClose()} />

                        })
                }
                <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the user')} ${selectedUser?.email}? ${t('Once deleted, this action cannot be undone!')}`} />
                <ConfirmModal modalOpen={emailConfirmModal} onClose={() => setEmailConfirmModal(false)} onConfirm={() => sendEmail()} title={t('Are you sure?')} children={`${t('Do you want to send the invite link for the customer')} ${selectedUser?.email}?`} />
            </CustomDialog>
        </>
    )
}

export default CustomerDetailModal