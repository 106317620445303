import React, { useContext, useEffect, useMemo, useRef } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from "react";
import { gql } from '@apollo/client';
import { Box, Button, Divider, Grid, MenuItem, Typography } from '@mui/material';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { responsiveString, splitCamelCase } from '../../../shared/utils/util-functions';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import Checkbox from '@mui/material/Checkbox';
import DataTable from '../../../shared/widgets/table/table.component';
import PaginationRounded from '../../../shared/widgets/pagination/pagination.component';


const CREATE_EVENT_SLOT_EVALUATOR = gql`
mutation CreateEventSlotUser($slotId: Int!, $userId: Int!) {
    createEventSlotUser(slotId: $slotId, userId: $userId) {
      success
      errors
      slotUser {
        id
      }
    }
  }
`
const GET_USERS_QUERY = gql`query Query($page: Int, $pageSize: Int, $search: String, $role: [String], $associationId: Int, $clubId: Int) {
    getAllUsers(page: $page, pageSize: $pageSize, search: $search, role: $role, associationId: $associationId, clubId: $clubId) {
      success
      totalUsers
      users {
        Association {
          associationName
        }
        Club {
          clubName
        }
        id
        lastName
        role
        firstName
        email
        contactNumber
        designation
      }
    }
  }
`
type ChildRef = {
    refetchData: (data: any) => void;
};



function EvaluvatorList({ handleNext, handleEventUsers, onClose, users, evaluatorsData }: any) {

    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState(null);
    const childRef = useRef<ChildRef | null>(null);
    const [usersData, setUsersData] = useState<any>([]);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const { refetch: usersRefetch } = useQuery(GET_USERS_QUERY, { skip: true });
    const [eventEvaluator, setEventEvaluator] = useState<any>([]);
    const [createEventEvalvator] = useMutation(CREATE_EVENT_SLOT_EVALUATOR);
    const pageMeta = useContext(PageMetaContext);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Submitted Players");
        }
    }, [pageMeta]);



    useEffect(() => {
        getUserData();
    }, [])

    useEffect(() => {
        if (evaluatorsData) {
            setEventEvaluator((prevState: any) => [...prevState, ...evaluatorsData]);
        }
    }, [evaluatorsData]);



    const getUserData = async () => {
        const { data } = await usersRefetch({
            search: "",
            page: null,
            pageSize: null,
            clubId: decodedTokenData.ClubId,
            role: decodedTokenData.roles === 'ClubAdmin' ? ['Evaluator', 'ClubAdmin'] : ['Evaluator']
        });

        if (users) {
            const idsToRemove = users.map((obj: any) => obj.id);
            const filteredUsersData = data?.getAllUsers?.users.filter((user: any) => !(idsToRemove.toString()).includes(user.id));
            setUsersData(filteredUsersData);
        }

    }




    const getEvaluatorsData = () => {
        if (evaluatorsData) {
            setEventEvaluator((prevState: any) => [...prevState, ...evaluatorsData]);
            if (areAllEvaluatorsUnique()) {
                handleEventUsers([...eventEvaluator, ...evaluatorsData]);
            }
        } else {
            handleEventUsers(eventEvaluator);
        }
    }



    const handleEventEvalator = (user: any, checked: boolean) => {
        const isSelected = eventEvaluator.includes(user.id);

        if (checked) {
            setEventEvaluator([...eventEvaluator, user]);
        } else {
            setEventEvaluator(eventEvaluator.filter((id: any) => id !== user));
        }

    }


    const areAllEvaluatorsUnique = () => {

        const selectedPlayersArray = Array.isArray(evaluatorsData) ? evaluatorsData : [];

        const duplicates = [...eventEvaluator, ...selectedPlayersArray]
        const playerIds = duplicates.map((player: any) => player.id);

        const uniquePlayerIds = new Set(playerIds);

        return uniquePlayerIds.size === playerIds.length;
    }


    const handleSubmit = async () => {

        getEvaluatorsData();

        if (areAllEvaluatorsUnique()) {
            handleNext();
        } else {
            enqueueSnackbar(t("The selected evaluators is already added to slot"), { variant: "error" });

        }


    }



    const TableConfig: TableColumn[] =
        [
            {
                label: "Select",
                align: "left",
                accessor: (row: any) => <Checkbox onChange={(event: any) => handleEventEvalator(row, event.target.checked)} checked={eventEvaluator.includes(row)} />,

            },

            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.firstName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Last Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Email",
                align: "left",
                accessor: (row: any) => row.email
            },
            {
                label: "Contact Number",
                align: "left",
                accessor: (row: any) => row.contactNumber
            },
            {
                label: "Role",
                align: "left",
                accessor: (row: any) => t(splitCamelCase(row.role))
            },
        ];



    const QUERY = `query Query($page: Int, $pageSize: Int, $search: String, $role: [String], $associationId: Int, $clubId: Int) {
        getAllUsers(page: $page, pageSize: $pageSize, search: $search, role: $role, associationId: $associationId, clubId: $clubId) {
          success
          totalUsers
          users {
            Association {
              associationName
            }
            Club {
              clubName
            }
            id
            lastName
            role
            firstName
            email
            contactNumber
            designation
          }
        }
      }
      `

    return (
        <div>
            {
                !users && <TablePageHoc columns={TableConfig as TableColumn[]} ref={childRef} query={QUERY}

                    params={{
                        search: searchText,
                        role: decodedTokenData.roles === 'ClubAdmin' ? ['Evaluator', 'ClubAdmin'] : ['Evaluator'],
                        clubId: decodedTokenData.ClubId,
                        associationId: null,
                    }}
                    sourceAccessor={(data) => data.getAllUsers.users}
                    countAccessor={(data) => data?.getAllUsers?.totalUsers}
                    pagination={{
                        enable: true,
                        page: page,
                        onChange: (page) => {
                            setPage(page)
                        }
                    }} />
            }

            {
                users && <DataTable dataSource={usersData} columns={TableConfig} />
            }

            <Divider sx={{ mx: -24, mb: 20, mt: 20 }} />
            <Grid container justifyContent={"flex-end"}>
                <Grid item xs={"auto"}>
                    <Button type="button" variant="text" color="secondary" onClick={onClose}>{t('Cancel')}</Button>
                </Grid>
                <Grid item xs={"auto"}>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit} >{t('Save')}</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default EvaluvatorList