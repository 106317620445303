import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useSnackbar } from 'notistack';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString } from '../../../shared/utils/util-functions';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { getWidget } from '../../../shared/widgets';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import moment from 'moment';
import AddSeason from './add-season';

type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void
};

const DELETE_SEASON_QUERY = gql`mutation Mutation($deleteSeasonId: ID!) {
  deleteSeason(id: $deleteSeasonId) {
    success
    errors
  }
}`

const SeasonList = () => {
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [selectedSeason, setSelectedSeason]: any[] = React.useState(null);
    const [editMode, setEditMode]: any = React.useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = React.useState(1);
    const childRef = useRef<ChildRef | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const pageMeta = useContext(PageMetaContext);
    const [deleteSeason] = useMutation(DELETE_SEASON_QUERY);

    const { t } = useTranslation();

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Season");
        }
        dispatch(stopPageLoading());
    }, [pageMeta]);

    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText ?? ""
        });
    };


    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedSeason) return;
        const variables = {
            deleteSeasonId: selectedSeason?.id,
        }

        try {
            const { data } = await deleteSeason({
                variables: variables
            });
            setSelectedSeason(null);

            if (data.deleteSeason.errors?.length > 0) {
                console.error('Error:', data.deleteSeason.errors);
                enqueueSnackbar(data.deleteSeason.errors[0], { variant: "error" });
            } else {
                childRef?.current?.handleDeleterowPagination()
                enqueueSnackbar(t("Season deleted successfully"), { variant: "success" });
                handleRefetch();
            }
        } catch (error) {
            setSelectedSeason(null);
            console.error('Mutation error:', error);
        }
    }


    const TableConfigClubs: TableColumn[] = [
        {
            label: "Action",
            align: "left",
            width: 130,
            accessor: (row: any) => {
                return <>
                    <ActionMenu
                        button={{
                            type: "icon-button",
                            icon: <MoreVertOutlined />,
                            size: 'small',
                            color: 'primary',
                            sx: { color: 'white' }

                        }}
                        children={
                            [
                                <MenuItem onClick={(event: any) => {
                                    setSelectedSeason(row);
                                    setAddModalOpen(true)
                                    event.stopPropagation();
                                    setEditMode(true)
                                }}>{t('Edit')}</MenuItem>,
                                <MenuItem onClick={(event: any) => {
                                    setSelectedSeason(row);
                                    setDeleteConfirmModal(true)
                                    event.stopPropagation();
                                }}>{t('Delete')}</MenuItem>
                            ]
                        }
                    />
                </>
            }
        }
        ,
        {
            label: "Season",
            align: "left",
            accessor: (row: any) => row?.title
        },
        {
            label: "Start date",
            align: "left",
            accessor: (row: any) => moment(new Date(parseInt(row?.from))).format("DD.MM.YYYY")
        },
        {
            label: "End date",
            align: "left",
            accessor: (row: any) => moment(new Date(parseInt(row?.to))).format("DD.MM.YYYY")
        }
    ]

    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }

    useUpdateEffect(() => {
        handleRefetch();
    }, [searchText], 300)

    const QUERY = `query GetRequestEvents($getRequestEventsId: Int) {
  getRequestEvents(id: $getRequestEventsId) {
    success
    errors
    requestEvents {
      from
      id
      to
      title
    }
  }
}
        `

    const ActionBarConfig: WidgetProps[] = [

        {
            type: "button",
            label: t(responsiveString({ xs: "Add", sm: "Add Season" })),
            startIcon: <Add />,
            onClick: () => {
                setAddModalOpen(true)
                setEditMode(false)
                setSelectedSeason(null)
            },
            sx: {
                order: {
                    xs: 1,
                    sm: 2
                }
            }
        },
    ]




    const onAddUserModalClose = () => {
        setAddModalOpen(false);
        handleRefetch()
    }




    return (
        <div>
            <TablePageHoc actions={ActionBarConfig} columns={TableConfigClubs} ref={childRef} query={QUERY} params={{
                search: searchText
            }}
                sourceAccessor={(data) => data.getRequestEvents.requestEvents} countAccessor={(data) => data.getRequestEvents.requestEvents.length}
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }}
            />
            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedSeason ? "Edit Season" : "Add Season",
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        children: <AddSeason season={selectedSeason} editMode={editMode} onSave={() => onAddUserModalClose()} onClose={() => {
                            setAddModalOpen(false)
                        }} />,
                    })
            }
            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the category')} ${selectedSeason?.categoryName}? ${t('Once deleted, this action cannot be undone!')}`} />
    
        </div>
    )
}

export default SeasonList