import React, { useMemo, useRef } from 'react'
import style from './Login.module.scss'
import { Box, Button, Checkbox, FormControlLabel, Grid, Link, Paper } from '@mui/material'
import Typography from '@mui/material/Typography';
import PasswordField from '../../widgets/password/password';
import { useState } from "react";
import TextField from '../../widgets/text-field/TextField';
import validator from 'validator';
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import { useTranslation } from 'react-i18next'
import { useHasWrapped } from '../../custom-hooks/useHasWrapped';
import TextInput from '../../widgets/text-field/TextInput';

export interface LoginProps {
    LeftContent: React.FC;
    logo: React.ReactNode;
    forgotPasswordClick?: () => void;
    onLogin?: (data: any) => void;
    hasError: boolean,
    errors: any
}

export default function LoginHoc({ LeftContent, logo, onLogin, forgotPasswordClick, hasError, errors }: LoginProps) {

    const rememberMeRef = useRef(null);
    const [linkRef, linkNotWrapped] = useHasWrapped(rememberMeRef);
    
    const [rememberMe, setRememberMe] = useState(true)

    const { t } = useTranslation();


    //function to set remember me to true
    const handleRememberMe = (event: any) => {
        setRememberMe(event.target.checked);
    }


    const handleSubmit = (user: any) => {
        if (onLogin) {
            onLogin({ email: user.email, password: user.password, rememberMe: rememberMe })
        }
    }

    

    return (
        <FormGroup onSubmit={handleSubmit}>
            <div className={style.login_wrapper}>
                <Grid container spacing={0}>
                    <Grid item xs sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <div className={style.banner_section}>
                            <LeftContent />
                        </div>
                    </Grid>
                    <Grid item xs="auto" className={style.content_section_wrapper} sx={{ width: { xs: '100%', lg: '37.76%' } }}>
                        <Paper className={style.content_section} sx={{ px: [30, 50, 80, 80, 100, 160], py: [30, 20] }}>
                            <Box className={style.content_box}>
                                <div className={style.logo_container}>
                                    {logo}
                                </div>
                                <div className={style.page_content}>
                                    <Typography variant="h6" component="div" gutterBottom color="primary">
                                        {t('Welcome')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" gutterBottom color={"text.secondary"} sx={{ mb: 25 }}>
                                        {t("Let's sign you in")}
                                    </Typography>

                                    <FormField name='email' validator={(value: string) => {
                                        if (!value) {
                                            return 'Email is required.'
                                        }
                                        if (!validator.isEmail(value)) {
                                            return 'Enter a valid email address.'
                                        }
                                        return null
                                    }} validateOnChange={false}>
                                        <TextInput id="email"
                                            label={t('Email')}
                                            variant="outlined"
                                            autoFocus={true}
                                            wrapperSx={{ mb: 10 }}
                                            fullWidth={true}
                                            
                                        />
                                    </FormField>

                                    <FormField name='password' validator={(value: string) => {
                                        if (!value) return "Password is required."
                                        return null
                                    }} validateOnChange={false}>
                                        <PasswordField
                                            id="password"
                                            label={t('Password')}
                                            wrapperSx={{ mb: 0 }}
                                            variant="outlined"
                                            fullWidth={true}
                                        />
                                    </FormField>
                                    <Grid container spacing={0} wrap="wrap-reverse" sx={{ mb: 25 }} alignItems={"center"} justifyContent={"space-between"}>
                                        <Grid item xs={"auto"} sx={{ whiteSpace: "nowrap"}}>
                                            {/* <FormControlLabel ref={rememberMeRef} sx={{mt: 9}} control={<Checkbox defaultChecked onChange={handleRememberMe} size='medium' />} label={<Typography variant='subtitle2' color={"primary"}>{t('Remember Me')}</Typography>} color='primary' /> */}
                                        </Grid>
                                        <Grid item xs sx={{ flexGrow: 1, textAlign: 'right', whiteSpace: "nowrap", mt: linkNotWrapped ? 9 : 0 }}>
                                            <Link ref={linkRef} href="javascipt:;" sx={{display: "inline-block"}} underline="none" onClick={() => forgotPasswordClick?.()} color="primary">{t('Forgot Password?')}</Link>
                                        </Grid>
                                    </Grid>
                                    <Button variant="contained" type="submit" >{t('Sign IN')}</Button>
                                </div>
                            </Box>


                        </Paper>
                    </Grid>

                </Grid>
            </div>
        </FormGroup>
    )
}
