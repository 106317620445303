import React, { useEffect } from 'react'
import CenterPage from '../../../shared/hoc/reset-password/center-page.hoc'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../store/reducers/AppSlice';

export default function SuccessPage() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    
    const onEvent = (event: any, data?: any) => {
        if(event === 'sign-in') {
            navigate('/');
        } else if(event === 'send-code') {
            verifyEmail(data);
        }
    }

    const verifyEmail = (data: any) => {    
        navigate("/reset-password/sample-key/verify")
    }

    useEffect(()=>{
        dispatch(stopPageLoading())
    },[])


    return (
        <div>
            <CenterPage 
                variant='success-page' 
                onEvent={onEvent}/>
        </div>
    )
}