import React, { useMemo } from 'react'
import AdminLayoutHoc from '../../shared/hoc/admin-layout/AdminLayout.default'
import logo from '../../assets/images/logo.svg'
import logo1 from "../../assets/images/logo-evalua.png";
import { GridViewOutlined, PersonOutline } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import Groups2Icon from '@mui/icons-material/Groups2';
import SportsSoccerOutlinedIcon from '@mui/icons-material/SportsSoccerOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InventoryIcon from '@mui/icons-material/Inventory';




export default function AdminLayout() {
  const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)


  const ADMIN_LAYOUT_CONFIG = useMemo(() => {
    const role = decodedTokenData?.roles ?? ""
    return {
      logo: <div><img src={logo} style={{ 'marginLeft': '-10px', 'height': '35px', 'width': '175px' }}></img></div>,
      logoSm: <div><img src={logo1}></img></div>,
      isOpen: false,
      menuLinks: [
        {
          text: 'DashBoard',
          icon: <GridViewOutlined />,
          link: "/admin/dashboard"
        },
        ...(
          role === "SuperAdmin" ? [
            {
              text: `Customer`,
              icon: <PeopleAltOutlinedIcon />,
              link: "/admin/customer-management"
            },
          ] : []
        ),
        ...(
          role === "SuperAdmin" ? [
            {
              text: `Packages`,
              icon: <InventoryIcon />,
              link: "/admin/package-management"
            },
          ] : []
        ),
        ...(
          role === "SuperAdmin" ? [
            {
              text: `Category`,
              icon: <CategoryIcon />,
              link: "/admin/category-management"
            },
          ] : []
        ),
        ...(
          role === "SuperAdmin"  ? [
            {
              text: 'Layout',
              icon: <StarBorderOutlinedIcon />,
              link: "/admin/layout-management"
            }
          ] : []
        ),
        ...(
          (role !== "Evaluator") ? [
            {
              text: 'Users',
              icon: <PersonOutline />,
              link: "/admin/user-management"
            }
          ] : []
        ),
        ...(
          role == "AssociationAdmin" || role === "Admin"? [
            {
              text: 'Players',
              icon: <PeopleAltOutlinedIcon />,
              link: "/admin/players"
            },
          ] : []
        ),

        ...(
          role == "FVRZAdmin" || role == "ClubAdmin" ? [
            {
              text: `${role !== "SuperAdmin" ? 'Associations' : 'Clubs'}`,
              icon: <SportsSoccerOutlinedIcon />,
              link: "/admin/association-management"
            }
          ] : []
        ),
    

        ...(
          role !== "SuperAdmin" && role !== "FVRZAdmin" ? [
            {
              text: 'Events',
              icon: <EmojiEventsOutlinedIcon />,
              link: "/admin/event-management"
            }
          ] : []
        ),
        ...(
          role == "ClubAdmin" || role == "AssociationAdmin" || role == "Admin" ? [
            {
              text: 'Layout',
              icon: <StarBorderOutlinedIcon />,
              link: "/admin/layout-management"
            }
          ] : []
        ),
        ...(
          role != "Evaluator"  && role !== "SuperAdmin" && role !== "Admin"? [
            {
              text: `${role !== "AssociationAdmin" ? 'Players' : 'Report'}`,
              icon: <PeopleAltOutlinedIcon />,
              link: "/admin/players-management"
            },
          ] : []
        ),
        ...(
          role === "SuperAdmin" ? [
            {
              text: `Season`,
              icon: <CalendarTodayIcon />,
              link: "/admin/season-management"
            },
          ] : []
        ),
     
      ]
    }
  }, [decodedTokenData])

  return (
    <AdminLayoutHoc {...ADMIN_LAYOUT_CONFIG} />
  )
}
