import React, { useEffect, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import TextField from '../../../shared/widgets/text-field/TextField';
import { Button, Divider, Grid, MenuItem } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import DateField from '../../../shared/widgets/date-field/DateField';
import { parseDate } from '../../../shared/utils/util-functions';
import dayjs from 'dayjs';
import 'dayjs/plugin/timezone';
import moment from 'moment';


const CREATE_ASSOCIATION_QUERY = gql`mutation Mutation($title: String , $from: String, $to: String) {
  createSeason(title: $title, from: $from, to: $to) {
    errors
    success
  }
}
  `


const UPDATE_ASSOCIATION_QUERY = gql`
mutation UpdateSeason($updateSeasonId: ID!, $title: String, $from: String, $to: String) {
  updateSeason(id: $updateSeasonId, title: $title, from: $from, to: $to) {
    success
    errors
  }
}
`


function AddSeason({ season, onSave, onClose, handleNext }: any) {

    const [resetField, setResetField] = useState<Function | undefined>(undefined);
    const [createSeason] = useMutation(CREATE_ASSOCIATION_QUERY);
    const [updateSeason] = useMutation(UPDATE_ASSOCIATION_QUERY);
    console.log(season)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = async (newSeason: any, callback: Function) => {

        if (season) {

            const variables = {
                updateSeasonId: season.id,
                title: newSeason.title,
                from: dayjs(newSeason.startDate,'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss Z'),
                to: dayjs(newSeason.endDate,'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss Z'),
            }
            try {
                const { data } = await updateSeason({
                    variables: variables
                });

                if (data.updateSeason.errors?.length > 0) {
                    enqueueSnackbar(data.updateSeason.errors[0], { variant: "error" });

                } else {
                    enqueueSnackbar(t("Season updated successfully"), { variant: "success" });
                    onSave();
                }
            } catch (error) {
                console.error('Mutation error:', error);
            }

        } else {
         
            const variables = {
                title: newSeason.title,
                from: dayjs(newSeason.startDate,'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss Z'),
                to: dayjs(newSeason.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss Z'),
            }

            try {
                const { data } = await createSeason({
                    variables: variables
                });

                if (data.createSeason.errors?.length > 0) {
                    console.error('Error:', data.createSeason.errors);
                    enqueueSnackbar(data.createSeason.errors[0], { variant: "error" });
                    callback();
                } else {
                    enqueueSnackbar(t("Season created successfully"), { variant: "success" });
                    onSave();
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }

        }

    };


    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
                <FormField name='title' value={season?.title ?? ""} validator={(value: string) => {
                    if (!value) return "Title name is required."
                    return null
                }} validateOnChange={false}>
                    <TextField id="title"
                        label={t("Title")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>

                <FormField name="startDate" value={season?.from ? moment(new Date(parseInt(season?.from))).format("DD.MM.YYYY"):  ''} validator={(value: string) => {
                    if (!value) return "Start date is required."
                    let date = parseDate(value);
                    if (!date) return "Invalid Date"
                    return null
                }} validateOnChange={false}>
                    <DateField id="Name" label={t("Start date")} sx={{ mb: 10 }} fullWidth={true} />
                </FormField>

                <FormField name="endDate" value={season?.to ? moment(new Date(parseInt(season?.to))).format("DD.MM.YYYY"):  ''} validator={(value: string) => {
                    if (!value) return "End date is required."
                    let date = parseDate(value);
                    if (!date) return "Invalid Date"
                    return null
                }} validateOnChange={false}>
                    <DateField id="Name" label={t("End date")} sx={{ mb: 10 }} fullWidth={true} />
                </FormField>


                <Divider sx={{ mx: -24, mb: 20 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
                    </Grid>
                </Grid>
            </FormGroup>
        </div>
    )
}

export default AddSeason