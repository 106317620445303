import React, { useContext, useEffect, useState } from 'react'
import BasicTabs from '../../../shared/hoc/tabs/Tabs'
import ClubListPage from '../clubs-list-page/ClubListPage';
import AssociationListPageTab from '../associations-list-page/AssociationListPage';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import CategoryListPage from './category-list';
import SubCategoryListPage from './subcategory-list';
import { stopPageLoading } from '../../../store/reducers/AppSlice';



function CategoryDashboardPage() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        stopPageLoading();
    }, [])

    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)


    return (
        <div>
            <BasicTabs tabsData={[
                {
                    label: 'Category', content: () => (
                        <div>
                            <CategoryListPage />
                        </div>
                    )
                },
                {
                    label: 'Sub Category', content: () => (
                        <div>
                            <SubCategoryListPage />
                        </div>
                    )
                },
            ]} />
        </div>
    )
}

export default CategoryDashboardPage