import React, { useContext, useEffect, useMemo, useRef } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from "react";
import { gql } from '@apollo/client';
import { Box, Button, Divider, Grid, MenuItem } from '@mui/material';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import Checkbox from '@mui/material/Checkbox';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';





type ChildRef = {
    refetchData: (data: any) => void;

};



function SubmittedPlayersList({ handleNext, handleEventPlayers, onClose, selectedPlayers, players, requestEventId, slotId, eventId, editMode }: any) {

    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState(null);
    const childRef = useRef<ChildRef | null>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [eventPlayer, setEventPlayer] = useState<any>([]);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();


    const getPlayerData = () => {
        if (selectedPlayers) {
            setEventPlayer((prevState: any) => [...prevState, ...selectedPlayers]);
            if (areAllPlayersUnique()) {
                handleEventPlayers([...eventPlayer, ...selectedPlayers]);
            }

        } else {
            handleEventPlayers(eventPlayer);
        }
    }

    useEffect(() => {

        if (selectedPlayers) {
            setEventPlayer((prevState: any) => [...prevState, ...selectedPlayers]);
        }
    }, [selectedPlayers]);

    useEffect(() => {
        handleRefetch();
    }, [searchText]);



    const handleEventPlayer = (player: any, checked: any) => {
        const isSelected = eventPlayer.some((p: any) => p.id === player.id);
        if (checked && !isSelected) {
            setEventPlayer([...eventPlayer, player]);
        } else if (!checked && isSelected) {
            setEventPlayer(eventPlayer.filter((p: any) => p.id !== player.id));
        }
    };



    const onSearchTextChange = (event: any) => {

        setSearchText(event.target.value)

    };



    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText,
            slotId: slotId,
            eventId: eventId,
            status: false,
            requestEventId: requestEventId,
            isSubmitted: true
        });
    };






    const ActionBarConfig: WidgetProps[] =
        [
            {
                type: "search-input",
                placeholder: "Search",
                onChange: onSearchTextChange,
                responsive: {
                    xs: 12,
                    sm: "auto"
                },
                sx: {
                    order: {
                        xs: 3,
                        sm: 2
                    }
                }
            },
        ];






    const TableConfig: TableColumn[] =
        [
            {
                label: "Select",
                align: "left",
                accessor: (row: any) => <Checkbox onChange={(event: any) => handleEventPlayer(row, event.target.checked)} checked={eventPlayer.some((p: any) => p.id === row.id)} />,

            },

            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.firstName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Last Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Date Of Birth",
                align: "left",
                accessor: (row: any) => row.dateOfBirth
            },


            {
                label: "Association",
                align: "left",
                accessor: (row: any) => row.Association?.associationName
            },

            // {
            //     label: "Strong Foot",
            //     align: "left",
            //     accessor: (row: any) => t(row.strongFoot)
            // },
            {
                label: "Goal Keeper",
                align: "left",
                accessor: (row: any) => t(row.goalKeeper)
            }
        ];



    const QUERY = `query GetAssociationPlayerList($requestEventId: ID, $associationId: ID, $search: String, $page: Int, $pageSize: Int, $eventId: Int, $slotId: Int) {
            getAssociationPlayerList(requestEventId: $requestEventId, associationId: $associationId, search: $search, page: $page, pageSize: $pageSize, eventId: $eventId, slotId: $slotId) {
              errors
              success
              totalPlayers
              players {
                telephone
                strongFoot
                relationship
                postCode
                playerAddress
                nationality
                lastName
                goalKeeper
                id
                firstName
                emailAddressImpFatherOrMother
                dateOfBirth
                contactPersonName
                city
                RequestEventId
                RequestEvent {
                  from
                  to
                  ownerId
                  id
                }
                Association {
                  associationName
                }
              }
            }
          }
          `


    const areAllPlayersUnique = () => {

        const selectedPlayersArray = Array.isArray(selectedPlayers) ? selectedPlayers : [];

        const duplicates = [...eventPlayer, ...selectedPlayersArray]
        const playerIds = duplicates.map((player: any) => player.id);

        const uniquePlayerIds = new Set(playerIds);

        return uniquePlayerIds.size === playerIds.length;
    }

    const handleSubmit = () => {
        getPlayerData();

        if (areAllPlayersUnique()) {
            handleNext();
        } else {
            enqueueSnackbar(t("The selected players is already added to slot"), { variant: "error" });

        }
    }

    return (
        <div>
            <TablePageHoc tableWrapperStyles={{ 'height': 'calc(80vh - 300px)', overflow: 'auto' }} columns={TableConfig as TableColumn[]} actions={ActionBarConfig as WidgetProps[]} ref={childRef} query={QUERY}

                params={{
                    search: searchText,
                    slotId: slotId,
                    eventId: eventId,
                    status: false,
                    requestEventId: requestEventId,
                }}

                sourceAccessor={(data) => data.getAssociationPlayerList.players}
                countAccessor={(data) => data?.getAssociationPlayerList?.totalPlayers}
                pagination={{
                    enable: true,
                    limit: 300,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }} />

            <Divider sx={{ mx: -24, mb: 20, mt: 20 }} />
            <Grid container justifyContent={"flex-end"}>
                <Grid item xs={"auto"}>
                    <Button type="button" variant="text" color="secondary" onClick={onClose}>{t('Cancel')}</Button>
                </Grid>
                <Grid item xs={"auto"}>
                    <Button type="submit" variant="contained" onClick={handleSubmit} color="primary">{t('Save')}</Button>
                </Grid>
            </Grid>


        </div>
    )
}

export default SubmittedPlayersList