import React, { useContext, useEffect, useState } from 'react'
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useTranslation } from 'react-i18next'
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import { useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';

const QUERY = gql`query Query {
  getOneAssociation {
    association {
      associationName
    }
  }
}`

export default function DashBoard() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { refetch: customerRefetch } = useQuery(QUERY, { skip: true });
  const [associationName, setAssociationName]: any = useState("");


  const pageMeta = useContext(PageMetaContext);

  useEffect(() => {
    getCustomerData();
  }, []);


  useEffect(() => {
    if (pageMeta) {
      const { setPageName, setBreadcrumbs } = pageMeta;
      setPageName(associationName);
    }
    dispatch(stopPageLoading())
  }, [pageMeta,associationName]);

  const getCustomerData = async () => {
    const { data } = await customerRefetch({
    });
    console.log(data?.getOneAssociation?.association?.associationName)
    setAssociationName(data?.getOneAssociation?.association?.associationName)
    return data;
  }

  return (
    <div>
      {t('DashBoard')}
    </div>

  )
}
