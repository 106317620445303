import React, { useEffect, useRef } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link } from '@mui/material'
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Add } from '@mui/icons-material';
import { getWidget } from '../../widgets';
import { ModalProps, ModalPropsBase } from '../../types/widget-types';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { FormField, FormGroup } from '../../utils/form-generator';
import PasswordField from '../../widgets/password/password';
import { useTranslation } from 'react-i18next'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/reducers/AppSlice';
import { useSnackbar } from 'notistack';
import TextField from '../../widgets/text-field/TextField';
import { useUpdateEffect } from '../../custom-hooks/useUpdateEffect';



const CustomDialog = (props: any) => {
    return <Dialog
        {...props}
        className={
            clsx({
                'sizeSmall': props.size == 'sm',
                'sizeLarge': props.size == 'lg',
                'sizeMedium': props.size == 'md'
            })
        }
    />
}


const CHANGE_PASSWORD_QUERY = gql`mutation ChangePassword($newPassword: String!, $confirmPassword: String!) {
    changePassword(newPassword: $newPassword, confirmPassword: $confirmPassword) {
      success
      errors
    }
  }`


const UPDATE_USER_QUERY = gql`mutation UpdateOneUser($firstName: String!, $lastName: String!, $email: String, $designation: String) {
    updateOneUser(firstName: $firstName, lastName: $lastName, email: $email, designation: $designation) {
      user {
        id
        lastName
        role
        firstName
        email
        designation
        Club {
          id
          contactNumber
          clubName
          clubAddress
        }
        Association {
          id
          associationName
        }
      }
      success
      errors
    }
  }`



const GET_USER_QUERY = `
  query GetOneUser {
    getOneUser {
      success
      errors
      user {
        id
        firstName
        lastName
        email
        role
        designation
        Club {
          id
          contactNumber
          clubName
          clubAddress
        }
        Association {
          id
          associationName
        }
      }
    }
  }`

type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void
};


function EditUserModal({ size, modalOpen, onClose }: any) {

    const [updateUser] = useMutation(UPDATE_USER_QUERY);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [userData, setUserData] = React.useState<any>(null)
    const { refetch } = useQuery(gql`${GET_USER_QUERY}`, { skip: true });
    const childRef = useRef<ChildRef | null>(null);

    useUpdateEffect(() => {
        if (modalOpen) {
            getUserData()
        }
    }, [modalOpen])



    const getUserData = async () => {
        const { data }: any = await refetch();
        setUserData(data);
    }


    const onCloseClick = () => {
        onClose?.()
    }




    const handleSubmit = async (user: any) => {
        const variables = {
            firstName: user.firstName,
            lastName: user.lastName,
        }
        
        try {
            const { data } = await updateUser({
                variables: variables
            })
            
            if (data.updateOneUser.errors?.length > 0) {
                console.error('Error:', data.updateOneUser.errors);
                enqueueSnackbar(data.updateOneUser.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(t("User profile updated successfully"), { variant: "success" });
                onCloseClick()
            }
        }
        catch (error) {
            console.log(error)
        }

    }


    useEffect(() => {
        getUserData()
    }, [handleSubmit])



    return (
        <CustomDialog
            open={modalOpen ?? false}
            onClose={() => onCloseClick()}
            size={size ?? 'sm'}
            sx={{ border: 0 }}

        >
            {
                userData && (
                    <FormGroup onSubmit={handleSubmit}>
                        <DialogTitle variant='h6' color={"primary"}>
                            {t('Edit Profile')}
                        </DialogTitle>
                        <Divider />
                        <DialogContent sx={{ pb: 0, pt: '25px' }}>
                            <FormField name='firstName' value={userData?.getOneUser?.user?.firstName ?? ""} validator={(value: string, ...data) => {
                                if (!value) return "First name is required."
                                return null
                            }} validateOnChange={false}>
                                <TextField id="firstName"
                                    label={t("First Name")}
                                    variant="outlined"
                                    sx={{ mb: 9 }}
                                    fullWidth={true}
                                />
                            </FormField>
                            <FormField name='lastName' value={userData?.getOneUser?.user?.lastName ?? ""} validator={(value: string) => {
                                return null
                            }} validateOnChange={false}>
                                <TextField id="lastName"
                                    label={t("Last Name")}
                                    variant="outlined"
                                    sx={{ mb: 9 }}
                                    fullWidth={true}
                                />
                            </FormField>

                        </DialogContent>
                        <Divider></Divider>
                        <DialogActions>
                            <Button autoFocus onClick={onCloseClick} color="secondary">
                                {t('Cancel')}
                            </Button>
                            <Button type='submit' variant='contained' autoFocus>
                                {t('Save')}
                            </Button>
                        </DialogActions>
                    </FormGroup>
                )
            }
        </CustomDialog>

    )
}

export default EditUserModal