import React from 'react'
import style from './center-page.module.scss'
import { Box, Card, Paper, Typography } from '@mui/material'
import { VerificationEmailHoc } from './layout/verification-email-hoc';
import SuccessPagehoc from './layout/success-page-hoc';
import ForgotPasswordHoc from './layout/forgot-password-hoc';
import SetPasswordHoc from './layout/set-password-hoc';
import { CenterPageProps } from '../../types/center-page';




export default function CenterPage({ logo, variant, width, ...props }: CenterPageProps) {

    const wrapperWidth = width ?? (variant == 'success-page' ? '550px' : '400px')

    return (
        <Paper className={style.page_wrapper}>
            <Box className={style.content_wrapper} sx={{ width: wrapperWidth }}>
                {
                    logo && <div className={style.logo_wrapper}>
                        {logo}
                    </div>
                }
                {
                    variant === "set-password" && <SetPasswordHoc {...props} />
                }
                {
                    variant === "forgot-password" && <ForgotPasswordHoc {...props} />
                }

                {
                    variant === "success-page" && <SuccessPagehoc {...props}/>
                }

                {
                    variant === "verification-email" && <VerificationEmailHoc {...props} />
                }

                {/* {
                    variant === "reset-password" && <ResetPasswordHoc />
                } */}

            </Box>
        </Paper>
    )
}
