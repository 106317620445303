import React from 'react';
import style from './AppBar.module.scss';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../../../store/reducers/AppSlice';
import MenuIcon from '@mui/icons-material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@mui/material';
import logo from '../../../assets/images/logo.svg'
import { SidebarProps } from '../../types/admin-layout';
import classNames from 'classnames';
import ProfileMenu from '../../../pages/admin/user-management/profile';




export default function AppBar({ isOpen }: SidebarProps) {
    

    const dispatch = useDispatch()

    return (

        <Box sx={{bgcolor: 'background.default'}} className={style.appBarWrapper}>
            <div className={style.logoSection}>
            
                {isOpen ? <img src={logo} /> : <img src={logo} style={{ 'height': '52px', 'width': '48px','marginLeft':'20px'}} />}
            </div>
            <div className={style.appBar}>
                <IconButton
                    color="primary"
                    className={style.icon}
                    onClick={() => dispatch(toggleSidebar())}
                    sx={{
                        'height': '21px',
                        'width': '18px'
                    }}
                >
                    <i className="fa-solid fa-bars" style={{ 'height': '16px', 'width': '14px' }}></i>
                </IconButton>


                <div className={style.notification}>
                    {/* <IconButton
                        color="primary"
                        className={style.icon}
                        sx={{
                            'height': '21px',
                            'width': '18px'
                        }}
                    >
                        <i className="fa-regular fa-bell"></i>
                    </IconButton> */}
                    <ProfileMenu/>
                </div>
            </div>
        </Box>

    );
}