import React, { createContext, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type CustomValidator = (value: string, ...data: any) => string | null;

type FieldState = {
    value: string;
    error: string | null;
    validator?: CustomValidator;
};

interface SetValueProps {
    validateOnChange: boolean;
}

type FormContextType = {
    registerField: (name: string, value: string, validator?: CustomValidator) => void;
    setValue: (name: string, value: any, validator?: CustomValidator, options?: SetValueProps) => void;
    resetForm: () => void;
    resetField: (name: string) => void;
    updateSubObject: (name: string | undefined, value: any, isValid: boolean) => void;
    fields: Record<string, FieldState>;
};

type FormArrayContextType = {
    updateSubObject: (name: string | undefined, value: any, isValid: boolean) => void;
};

const FormContext = createContext<FormContextType | undefined>(undefined);
const FormArrayContext = createContext<FormArrayContextType | undefined>(undefined);

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("FormField must be used within a FormGroup");
    }
    return context;
};


type FormGroupProps = {
    name?: string;
    children: React.ReactNode;
    onSubmit?: (values: Record<string, any>, callback: Function) => void;
    onFormInit?: (resetField: Function, resetForm: Function) => void;
};

type FormArrayProps = {
    name: string;
    children: React.ReactNode;
};


export interface FormGroupRef {
    getValues: () => Record<string, any>;
    removeItem?: (name:string) => void;
}

const FormGroup = forwardRef<FormGroupRef, FormGroupProps>(({ name:groupName, children, onSubmit, onFormInit }:FormGroupProps, userProvidedRef) => {
    const [fields, setFields] = useState<Record<string, FieldState>>({});
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitTimeoutRef = useRef<number | null>(null);
    const parentContext = useContext(FormContext);

    useImperativeHandle(userProvidedRef, () => ({
        getValues: () => getValues(fields, false, false).values,
    }));

    const registerField = (name: string, value: any, validator?: CustomValidator) => {
        setFields((prevFields) => {
            const currentFields = { ...prevFields, [name]: { value: value ?? '', error: null, validator } };
            if(parentContext) {
                const {updateSubObject} = parentContext;
                const res = getValues(currentFields, true, false);
                
                updateSubObject(groupName ?? "", res.values, res.validObject);
            }
            return (currentFields)
        });
        
    }

    const setValue = (name: any, newValue: any, validator?: CustomValidator, options?: SetValueProps) => {
        setFields((prevFields: any) => {
            const field = prevFields[name] || {};
            let error = null;
            if (!options || (options && options.validateOnChange)) {
                if (field.validator || validator) {
                    error = (field.validator || validator)(newValue, fields);
                    error = t(error);
                }
            }
            const currentFields = { ...prevFields, [name]: { ...field, value: newValue, error, validator: field.validator || validator } };
            if(parentContext) {
                const {updateSubObject} = parentContext;
                const res = getValues(currentFields, true, false);
                
                updateSubObject(groupName ?? "", res.values, res.validObject);
            }
            return currentFields;
        });
    };

    const updateSubObject = (name: string | undefined, value: any, isValid: boolean) => {
        if(name) {
            setValue(name, value, (value: any) => {
                return isValid ? null : `Invalid ${name}`;
            })
        }
    }

    const resetForm = () => {

        setFields((prevFields: any) => {
            Object.keys(prevFields).forEach((name) => {
                const field = prevFields[name] || {};
                let error = null;
                prevFields[name] = { ...field, value: '', error, validator: field.validator };
            });
            return { ...prevFields };
        });
    };

    const resetField = (name: string, value?:any) => {
        setFields((prevFields: any) => {
            const field = prevFields[name] || {};
            let error = null;
            
            return { ...prevFields, [name]: { ...field, value: value??'', error, validator: field.validator } };
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        
        event.preventDefault();
        if(isSubmitting) return;


        if (submitTimeoutRef.current) {
            clearTimeout(submitTimeoutRef.current);
        }

        submitTimeoutRef.current = window.setTimeout(() => {
            const res = getValues(fields, true, true);
            
            setFields({ ...res.fields });
            
            if (res.validObject) {
                onSubmit?.(res.values, () => {
                    setIsSubmitting(false);
                });
            }
    
        }, 300);

    };

    const getValues = (fields:Record<string, FieldState>, validate = false, updateField = false) => {
        const values: any = {};
        let allValid = true;
        for (let [name, field] of Object.entries(fields)) {
            if(validate) {
                let error = null;
                if (field.validator) {
                    error = field.validator(field.value, fields);
                    if (error) {
                        allValid = false;
                        if(updateField) {
                            fields[name].error = t(error);
                        }
                    }
                }
            }
            values[name] = field.value;
        }
        return {
            validObject: allValid,
            values,
            fields
        }
    }

    useEffect(() => {
        // Call the onFormInit prop when the component mounts
        if (onFormInit) {
            onFormInit(resetField, resetForm);
        }
    }, []);
    

    return (
        <FormContext.Provider value={{ setValue, fields, registerField, resetForm, resetField, updateSubObject }}>
            {
                onSubmit ? (
                    <form onSubmit={handleSubmit}>
                        {children}
                    </form>
                ) : (
                    <>{children}</>
                )
            }
        </FormContext.Provider>
    );
});




const FormArray = forwardRef<FormGroupRef, FormGroupProps>(({ name:arrayName, children }:FormGroupProps, userProvidedRef)  => {
    const [fields, setFields] = useState<Record<string, any>>({});
    const { t } = useTranslation();
    const parentContext = useContext(FormContext);

    
    useImperativeHandle(userProvidedRef, () => ({
        getValues: () => Object.keys(fields).map(key => fields[key]),
        removeItem: removeField
    }));


    const registerField = (name: string, value: any, validator?: CustomValidator) => {
        console.log(name, value);
        
    }

    const removeField = (name: string) => {
        
        
        setFields((prevFields) => {
            const updatedFields = { ...prevFields };
            delete updatedFields[name]; // Remove the field
            return updatedFields;
        });
    };

    const setValue = (name: any, newValue: any, validator?: CustomValidator, options?: SetValueProps) => {
    };

    const updateSubObject = (name: string | undefined, value: any, isValid: boolean) => {
        
        if(name) {
            setFields(prevFields => ({
                ...prevFields,
                [name]: value
            }));
        }
    }

    const resetForm = () => {
    };

    const resetField = (name: string, value?:any) => {
    };

    useEffect(() => {
        if(parentContext) {
            const {updateSubObject} = parentContext;
            const data = Object.keys(fields).map(key => fields[key])
            updateSubObject(arrayName ?? "", data, true);
            
        }
    }, [fields]);
    

    return (
        <FormContext.Provider value={{ setValue, fields, registerField, resetForm, resetField, updateSubObject }}>
            <>{children}</>
        </FormContext.Provider>
    );
});



type FormFieldProps = {
    name: string;
    value?: any;
    initialValue?: string;
    children: React.ReactElement;
    validator?: CustomValidator;
    validateOnChange?: boolean;
};

const FormField: React.FC<FormFieldProps> = ({ name, children, validator, value, validateOnChange, initialValue }) => {
    const { registerField, setValue, fields } = useFormContext();
    const fieldState = fields[name] || { value: value ?? initialValue ?? '', error: null };

    useEffect(() => {
        registerField(name, value ?? initialValue ?? '', validator);
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setValue) {
            setValue(name, e.target.value, validator, { validateOnChange: validateOnChange ?? false });
        }
    };

    return React.cloneElement(children, {
        name,
        value: fieldState.value,
        onChange: (e: any) => {
            handleChange(e);
            if (children.props.onChange) {
                children.props.onChange(e);
            }
        },
        error: !!fieldState.error,
        helperText: fieldState.error
    });
};

export { FormField, FormGroup, FormArray };
