import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useSnackbar } from 'notistack';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString } from '../../../shared/utils/util-functions';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { getWidget } from '../../../shared/widgets';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import AddCategory from './add-category';
import AddSubCategory from './add-subcategory';

type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void
};

const DELETE_SUB_CATEGORY_QUERY = gql`mutation DeleteOneSubCategory($deleteOneSubCategoryId: ID!) {
  deleteOneSubCategory(id: $deleteOneSubCategoryId) {
    success
    errors
  }
}`

const SubCategoryListPage = () => {
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [selectedSubCategory, setSelectedSubCategory]: any[] = React.useState(null);
    const [editMode, setEditMode]: any = React.useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = React.useState(1);
    const childRef = useRef<ChildRef | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const pageMeta = useContext(PageMetaContext);
    const [deleteCategory] = useMutation(DELETE_SUB_CATEGORY_QUERY);

    const { t } = useTranslation();

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Sub category");
        }
        dispatch(stopPageLoading());
    }, [pageMeta]);

    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText ?? ""
        });
    };


    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedSubCategory) return;
        const variables = {
            deleteOneSubCategoryId: selectedSubCategory?.id,
        }

        try {
            const { data } = await deleteCategory({
                variables: variables
            });
            setSelectedSubCategory(null);

            if (data.deleteOneSubCategory.errors?.length > 0) {
                console.error('Error:', data.deleteOneSubCategory.errors);
                enqueueSnackbar(data.deleteOneSubCategory.errors[0], { variant: "error" });
            } else {

                childRef?.current?.handleDeleterowPagination()
                enqueueSnackbar(t("Category deleted successfully"), { variant: "success" });

            }
        } catch (error) {
            setSelectedSubCategory(null);
            console.error('Mutation error:', error);
        }
    }


    const TableConfigClubs: TableColumn[] = [
        {
            label: "Action",
            align: "left",
            width: 130,
            accessor: (row: any) => {
                return <>
                    <ActionMenu
                        button={{
                            type: "icon-button",
                            icon: <MoreVertOutlined />,
                            size: 'small',
                            color: 'primary',
                            sx: { color: 'white' }

                        }}
                        children={
                            [
                                <MenuItem onClick={(event: any) => {
                                    setSelectedSubCategory(row);
                                    setAddModalOpen(true)
                                    event.stopPropagation();
                                    setEditMode(true)
                                }}>{t('Edit')}</MenuItem>,
                                <MenuItem onClick={(event: any) => {
                                    setSelectedSubCategory(row);
                                    setDeleteConfirmModal(true)
                                    event.stopPropagation();
                                }}>{t('Delete')}</MenuItem>
                            ]
                        }
                    />
                </>
            }
        }
        ,
        {
            label: "Sub Category",
            align: "left",
            accessor: (row: any) => row?.subcategoryName
        },
        {
            label: "Category",
            align: "left",
            accessor: (row: any) => row?.Category?.categoryName
        }
    ]

    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }

    useUpdateEffect(() => {
        handleRefetch();
    }, [searchText], 300)

    const QUERY = `query Query($search: String, $page: Int, $pageSize: Int) {
  getAllSubCategories(search: $search, page: $page, pageSize: $pageSize) {
    errors
    totalSubCategories
    success
    subcategories {
      subcategoryName
      id
      Category {
        categoryName
        id
      }
    }
  }
}
        `

    const ActionBarConfig: WidgetProps[] = [

        {
            type: "search-input",
            placeholder: "Search",
            onChange: onSearchTextChange,
            responsive: {
                xs: 12,
                sm: "auto"
            },
            sx: {
                order: {
                    xs: 2,
                    sm: 1
                }
            }
        },
        {
            type: "button",
            label: t(responsiveString({ xs: "Add", sm: "Add Category" })),
            startIcon: <Add />,
            onClick: () => {
                setAddModalOpen(true)
                setEditMode(false)
                setSelectedSubCategory(null)
            },
            sx: {
                order: {
                    xs: 1,
                    sm: 2
                }
            }
        },
    ]




    const onAddUserModalClose = () => {
        setAddModalOpen(false);
        handleRefetch()
    }




    return (
        <div>
            <TablePageHoc actions={ActionBarConfig} columns={TableConfigClubs} ref={childRef} query={QUERY} params={{
                search: searchText
            }}
                sourceAccessor={(data) => data.getAllSubCategories.subcategories} countAccessor={(data) => data.getAllSubCategories.totalSubCategories}
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }}
            />
            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedSubCategory ? "Edit Category" : "Add Category",
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        children: <AddSubCategory category={selectedSubCategory} editMode={editMode} onSave={() => onAddUserModalClose()} onClose={() => {
                            setAddModalOpen(false)
                        }} />,
                    })
            }
            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the category')} ${selectedSubCategory?.subcategoryName}? ${t('Once deleted, this action cannot be undone!')}`} />

        </div>
    )
}

export default SubCategoryListPage