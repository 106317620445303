import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    accessToken: "",
    isLoggedIn: false,
    refreshToken: "",
    decodedTokenData: {}
}



export const AuthSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {

        updateAccessTokenAndLoginStatus: (state, action) => {

            const { accessToken, isLoggedIn, decodedTokenData } = action.payload

            return {
                ...state,
                accessToken,
                isLoggedIn,
                decodedTokenData: decodedTokenData ?? {}
            }

        },

        updateRefreshToken: (state, action) => {
            const { refreshToken } = action.payload

            return {
                ...state,
                refreshToken
            }
        },

        updateAccessToken: (state, action) => {

            const { accessToken } = action.payload

            return {
                ...state,
                accessToken
            }
        },

        logoutUser: (state, action) => {
            const { accessToken, isLoggedIn, refreshToken } = action.payload
            return {
                ...state,
                accessToken,
                isLoggedIn,
                refreshToken
            }
        },

        updateSlotEvent: (state, action) => {
            const { slotId, eventId } = action.payload

            return {
                ...state,
                slotId,
                eventId,
            }
        }

        // updateTokenAndUpdateLoginStatus: (state, action) => {
        //     const { token, isLoggedIn } = action.payload

        //     return {
        //         ...state,
        //         token,
        //         isLoggedIn
        //     }
        // },


    }

})

export const { updateAccessToken, updateAccessTokenAndLoginStatus, updateRefreshToken, logoutUser, updateSlotEvent } = AuthSlice.actions;

export default AuthSlice.reducer;