import React, { useTransition, useState, useEffect } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Button, Divider, Grid, MenuItem, Typography } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { addToast, stopPageLoading } from '../../../store/reducers/AppSlice';
import { useTranslation } from 'react-i18next'
import DateTime from '../../../shared/widgets/date-time/DateTime';





function EventSlotGeneration({ index, slot }: any) {


    useEffect(() => {
        dispatch(stopPageLoading())
    }, [])

    const dispatch = useDispatch();
    const { t } = useTranslation();


 
    //     return null; // Return null if slot data is not available
    // }

    return (
        <div style={{ 'marginTop': '10px' }}>
            <FormGroup name={index.toString()}>
                <Grid container spacing={12} sx={{ padding: '12px 0 0 12px' }}>

                    <Grid item xs={12} >
                        <FormField name='id' value={slot?.id ?? undefined} validateOnChange={false}><></></FormField>
                        <FormField name='slotTitle' value={slot?.title ?? ""} validator={(value: string) => {
                            if (!value) return "Slot name is required."
                            return null
                        }} validateOnChange={false}>
                            <TextField id="slotTitle"
                                label={t("Slot Name")}
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                            />
                        </FormField>
                    </Grid>

                    <Grid item xs={12}>
                        <FormField name='location' value={slot?.location ?? ""} validator={(value: string) => {
                            if (!value) return "Location is required."
                            return null
                        }} validateOnChange={false}>
                            <TextField id="location"
                                label={t("Location")}
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                            />
                        </FormField>
                    </Grid>


                    <Grid item xs={12} md={6} sx={{ mb: 10 }}>
                        <FormField name="startTime" value={ slot?.startTime ? (new Date( parseInt(slot?.startTime))).toISOString() : ""} validator={(value: string) => {
                            if (!value) return "Start time is required."
                            // let date = parseDate(value);
                            // if (!date) return "Invalid Date"
                            return null
                        }} validateOnChange={false}>
                            <DateTime id="startTime" label={t("Start Time")} sx={{ mb: 10 }} fullWidth={true} minDate={new Date()} />

                        </FormField>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ mb: 10 }}>
                        <FormField name="endTime" value={ slot?.endTime ? (new Date( parseInt(slot?.endTime))).toISOString() : ""} validator={(value: string) => {
                            if (!value) return "End time is required."
                            // let date = parseDate(value);
                            // if (!date) return "Invalid Date"
                            return null
                        }} validateOnChange={false}>
                            <DateTime id="endTime" label={t("End Time")} fullWidth={true} minDate={new Date()} />

                        </FormField>
                    </Grid>

                </Grid>
            </FormGroup>
        </div >
    )
}

export default EventSlotGeneration