import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from "react";
import { gql } from '@apollo/client';
import { Box, Button, Divider, Grid, MenuItem, Typography } from '@mui/material';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { responsiveString, splitCamelCase } from '../../../shared/utils/util-functions';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import DataTable from '../../../shared/widgets/table/table.component';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';



const CREATE_EVENT_SLOT_EVALUATOR = gql`
  mutation CreateEventSlotUser($slotId: Int!, $userId: [Int]!) {
      createEventSlotUser(slotId: $slotId, userId: $userId) {
        success
        errors
        message
      }
    }
  `

type ChildRef = {
    refetchData: (data: any) => void;
};



function PreviewSubmittedEvaluatorsList({ data, onClose, slotId, onAddClose, handleEventUsers }: any) {

    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState(null);
    const childRef = useRef<ChildRef | null>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [previewEvaluators, setPreviewEvaluators] = useState(data);
    const [createEventEvalvator] = useMutation(CREATE_EVENT_SLOT_EVALUATOR);
    const pageMeta = useContext(PageMetaContext);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Submitted Players");
        }
    }, [pageMeta]);



    const handleEventPlayer = (player: any) => {
        setPreviewEvaluators(previewEvaluators.filter((id: any) => id !== player));
        handleEventUsers(previewEvaluators.filter((id: any) => id !== player))
    }



    const handleSubmit = async () => {

        let hasError = false;

        try {

            const variables = {
                slotId: parseInt(slotId),
                userId: previewEvaluators.map((user: any) => parseInt(user.id))
            };

            const { data } = await createEventEvalvator({
                variables: variables
            });


            if (data.createEventSlotUser.errors?.length > 0) {
                enqueueSnackbar(t("Error adding event users"), { variant: "error" });
            } else {
                enqueueSnackbar(t("Evaluators added successfully"), { variant: "success" });
                onClose();
                onAddClose();
            }



        } catch (error) {
            console.error('Mutation error:', error);
        }
    }



    const TableConfig: TableColumn[] =
        [
            {
                label: "Remove",
                align: "left",
                accessor: (row: any) => <RemoveCircleOutlineIcon onClick={(event: any) => handleEventPlayer(row)} style={{ 'cursor': 'pointer', 'display': 'flex', 'alignItems': 'center', 'height': '20px', 'width': '20px' }} />,
            },
            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.firstName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Last Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Email",
                align: "left",
                accessor: (row: any) => row.email
            },
            {
                label: "Contact Number",
                align: "left",
                accessor: (row: any) => row.contactNumber
            },
            {
                label: "Role",
                align: "left",
                accessor: (row: any) => t(splitCamelCase(row.role))
            },
        ];



    return (
        <div>
            {
                previewEvaluators && previewEvaluators.length > 0 ? (
                    <>
                        <DataTable dataSource={previewEvaluators} columns={TableConfig} />

                        <Divider sx={{ mx: -24, mb: 20, mt: 20 }} />
                        <Grid container justifyContent={"flex-end"}>
                            <Grid item xs={"auto"}>
                                <Button type="button" variant="text" color="secondary" onClick={onClose}>{t('Cancel')}</Button>
                            </Grid>
                            <Grid item xs={"auto"}>
                                <Button type="submit" variant="contained" onClick={handleSubmit} color="primary">{t('Save')}</Button>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', 'justifyContent': 'center', alignItems: 'center', my: { xs: 30, lg: 50 } }}>
                            <Typography >{t("No evaluators has been selected for the event slot.")}</Typography>
                            <Typography ></Typography>
                        </Box>
                    </>
                )
            }




        </div>
    )
}

export default PreviewSubmittedEvaluatorsList