import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useMutation, gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next'
import "./stepper.module.scss";
import EvaluvatorList from './evaluvator-list';
import PreviewSubmittedEvaluatorsList from './preview-evaluators-list';




export default function HorizontalNonLinearStepper({ onSave, onClose, slotId, users}: any) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [evaluatorsData, setEvaluatorsData] = React.useState<any>(null);


    const { t } = useTranslation();

    const steps = ['Add Evaluators', 'Preview'];
 

    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? 
                
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
     
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);

    };

    const handleEventUsers = (data:any) => {
        setEvaluatorsData(data);
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            {t(label)}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>
               
                {
                    activeStep === 0 && <EvaluvatorList handleNext={handleNext} handleEventUsers={handleEventUsers} onClose={onClose} users={users} evaluatorsData={evaluatorsData}/>
                }
                {
                    activeStep === 1 && <PreviewSubmittedEvaluatorsList handleNext={handleNext} data={evaluatorsData} onClose={onClose} slotId={slotId} handleEventUsers={handleEventUsers}/>
                }

            </div>
        </Box>
    );
}