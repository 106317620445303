import React, { useEffect } from 'react'
import BasicTabs from '../../../shared/hoc/tabs/Tabs'
import RegisteredPlayersList from '../registered-players-list/RegisteredPlayersList';
import SubmittedPlayersList from '../submitted-players-list/SubmittedPlayersList';
import { useSelector } from 'react-redux';






function PlayersListPage() {
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    

    return (
        <div>
            {
                decodedTokenData?.roles === 'AssociationAdmin' ? (
                    <BasicTabs tabsData={[
                        {
                            label: 'Registered Players', content: () => (
                                    <RegisteredPlayersList/>
                            )
                        },
                        {
                            label: 'Submitted Players', content: () => (
                                    <SubmittedPlayersList/>
                            )
                            
                        },
        
                    ]} />

                ) : (
                    <SubmittedPlayersList/>
                )
            }
     
        </div>
    )
}

export default PlayersListPage

