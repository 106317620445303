import React, { useEffect, useState } from 'react'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Box, Button, Grid, MenuItem } from '@mui/material'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import { gql, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';



const GET_CLUBS_QUERY = gql`query Query($page: Int, $pageSize: Int) {
    getAllClubs(page: $page, pageSize: $pageSize) {
      clubs {
        clubAddress
        clubName
        contactNumber
        id
      }
      errors
      success
      totalClubs
    }
  }
  `

function FilterClub({ filterData, onFilterChange, onClose }: any) {

    const { t } = useTranslation();
    const [resetForm, setResetForm] = useState<any>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [clubsData, setClubsData] = useState<any>(null);

    const { clubId } = useParams();

    const { refetch: clubRefetch } = useQuery(GET_CLUBS_QUERY, { skip: true });


    useEffect(() => {
        if (decodedTokenData.roles == 'FVRZAdmin') {
            getClubData();
        }
        
    }, [decodedTokenData])

    const getClubData = async () => {
        const { data } = await clubRefetch({
            search: "",
            page: null,
            pageSize: null
        });
        setClubsData(data);
        return data;
    }



    const handleSubmit = async (user: any, callback: Function) => {
        const filters = {
            clubId: parseInt(user.clubId) || null,
        };

        onFilterChange({
            submit: true,
            filters: filters,
            callback
        });

        onClose();
    };


    return (
        // <div>
        <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetForm(() => resetForm)}>
            <Box sx={{ width: '300px', padding: '8px 16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
 
     
                {
                    decodedTokenData.roles == 'FVRZAdmin' && (
                        <FormField name='clubId' value={filterData?.clubId ?? ""}>
                        <TextField id="clubs"
                            label={t("Club")}
                            select
                            variant="outlined"
                            sx={{ mb: 10 }}
                            fullWidth={true}
                        >
                          
                               {clubsData ? clubsData.getAllClubs.clubs.map((club: any) => (
                                <MenuItem key={"club" + club.id} value={club.id}>
                                    {club.clubName}
                                </MenuItem>
                            )) : <MenuItem></MenuItem>}
                        </TextField>
                    </FormField>

                    )
                }
   
                <Grid sx={{alignSelf:'end'}}>
                    <Button type="button" variant="text" onClick={() => {
                        resetForm();
                        onFilterChange({
                            submit: true,
                            filters: {
                                role: null,
                                clubId: null,
                                associationId: null,
                            },
                        });
                        // onClose();
                    }} color="primary">{t('Clear')}</Button>
                    <Button type="submit" variant="contained" color="primary">{t('Submit')}</Button>
                </Grid>
            </Box>
        </FormGroup>
        // </div>
    )
}

export default FilterClub