import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import PasswordField from '../../../shared/widgets/password/password'
import { Button, Divider, Grid, MenuItem } from '@mui/material'
import { gql, useMutation, useQuery } from '@apollo/client'
import { uid as uniqueId } from 'uid'
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { checkPassword } from '../../../shared/utils/util-functions'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import MuiTelephoneInput from '../../../shared/widgets/tel-input/MuiTelInput'



const CREATE_USER_QUERY = gql`mutation CreateUser($firstName: String!, $lastName: String, $email: String!,$contactNumber: String!, $password: String!, $designation: String, $role: String!, $clubId: Int, $associationId: Int) {
    createUser(firstName: $firstName, lastName: $lastName, email: $email, contactNumber: $contactNumber, password: $password, designation: $designation, role: $role, clubId: $clubId, associationId: $associationId) {
      errors
      success
      user {
        firstName
        email
        id
      }
    }
  }`

const GET_CLUBS_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int) {
    getAllClubs(search: $search, page: $page, pageSize: $pageSize) {
      clubs {
        clubAddress
        clubName
        contactNumber
        id
      }
      errors
      success
      totalClubs
    }
  }
  `

const GET_ASSOCIATIONS_QUERY = gql`query GetAllAssociations($clubId: Int, $search: String, $page: Int, $pageSize: Int) {
    getAllAssociations(clubId: $clubId, search: $search, page: $page, pageSize: $pageSize) {
      associations {
        associationName
        id
      }
      errors
      success
      totalAssociations
    }
  }`

const UPDATE_USER_QUERY = gql`mutation UpdateOneUser($firstName: String!, $lastName: String, $email: String, $designation: String, $contactNumber: String, $updateOneUserId: ID, $role: String) {
    updateOneUser(firstName: $firstName, lastName: $lastName, email: $email,contactNumber: $contactNumber, designation: $designation, id: $updateOneUserId, role: $role) {
      success
      user {
        Association {
          associationName
        }
        Club {
          clubName
        }
        email
      }
    }
  }`

type ChildRef = {
    refetchData: (data: any) => void;
};




export default function AddUser({ defaultRole, latestClubId, latestAssociationId, users, onSave, onClose, editMode }: any) {

    const { t } = useTranslation();

    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [selectedRole, setSelectedRole] = useState(defaultRole ?? users?.role ?? decodedTokenData?.roles);
    const [selectedDesignation, setSelectedDesignation] = useState('Coordinator')
    const [resetField, setResetField] = useState<Function | undefined>(undefined);
    const [clubsData, setClubsData] = useState<any>(null);
    const [associationData, setAssociationData] = useState<any>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [createUser] = useMutation(CREATE_USER_QUERY);
    const [updateUser] = useMutation(UPDATE_USER_QUERY);


    useEffect(() => {
        if (decodedTokenData.roles === 'FVRZAdmin') {
            getClubData();
        } else if (decodedTokenData.roles == 'ClubAdmin') {
            getAssociationData(parseInt(decodedTokenData.ClubId) ?? null)
        }
    }, [decodedTokenData])



    const getClubData = async () => {
        const { data } = await clubRefetch({
            search: "",
            page: null,
            pageSize: null,

        });
        setClubsData(data);
        return data;
    }

    const onCloseUser = () => {
        onClose();
    }




    const getAssociationData = async (clubId: any) => {
        if (!clubId) return;
        const { data } = await associationFetch({
            search: "",
            page: null,
            pageSize: null,
            clubId
        });
        setAssociationData(data);
        return data;
    }




    const { refetch: clubRefetch } = useQuery(GET_CLUBS_QUERY, { skip: true });
    const { refetch: associationFetch } = useQuery(GET_ASSOCIATIONS_QUERY, { skip: true });



    const SuperAdminRoles = [
        {
            value: 'FVRZAdmin',
            label: 'FVRZ Admin',
        },
        {
            value: 'ClubAdmin',
            label: 'Club Admin',
        },
        {
            value: 'AssociationAdmin',
            label: 'Association Admin',
        },

    ];


    const ClubAdminRoles = useMemo(() => {
        return [

            {
                value: 'AssociationAdmin',
                label: 'Association Admin',
            },
            {
                value: 'Evaluator',
                label: 'Evaluator',
            },

        ]
    }, [])


    const AdminRoles = [
        {
            value: 'Admin',
            label: 'Admin',
        },
        {
            value: 'Evaluator',
            label: 'Evaluator',
        },
    ]



    const designations = [
        {
            value: 'Coordinator',
            label: 'Coordinator'
        },
        {
            value: 'Junior Manager',
            label: 'Junior Manager'
        }
    ]



    const handleSubmit = async (user: any, callback: Function) => {

        if (users) {
            const variables = {
                updateOneUserId: users.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                contactNumber: user.contactNumber,
                designation: user.designation,
                role: user.role
            }

            try {
                const { data } = await updateUser({
                    variables: variables
                });

                if (data.updateOneUser.errors?.length > 0) {
                    console.error('Error:', data.updateUser.errors);
                    enqueueSnackbar(data.updateOneUser.errors[0], { variant: "error" });
                } else {

                    enqueueSnackbar(t("User updated successfully"), { variant: "success" });
                    onSave();
                }
            } catch (error) {
                console.error('Mutation error:', error);
            }
        } else {
            let newClubId = latestClubId ? parseInt(latestClubId) : parseInt(user.clubId);
            let newAssId = latestAssociationId ? parseInt(latestAssociationId) : parseInt(user.associationId);
            let newRole = defaultRole ?? user.role;
            const variables = {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                contactNumber: user.contactNumber,
                password: user.password ?? "",
                designation: user.designation ? user.designation : null,
                role: newRole,
                clubId: decodedTokenData.roles !== 'FVRZAdmin' ? parseInt(decodedTokenData.ClubId) : newClubId,
                associationId: newRole === 'AssociationAdmin' ? newAssId : null,
            }
            try {
                const { data } = await createUser({
                    variables: variables
                });

                if (data.createUser.errors?.length > 0) {
                    console.error('Error:', data.createUser.errors);
                    const errorIndex = data.createUser.errors?.length;
                    enqueueSnackbar(data.createUser.errors[errorIndex - 1], { variant: "error" });
                    callback();
                } else {

                    enqueueSnackbar(t("User created successfully"), { variant: "success" });
                    onSave()
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }
        }


    };



    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
                <FormField name='firstName' value={users?.firstName ?? ""} validator={(value: string, ...data) => {
                    if (!value) return "First name is required."
                    return null
                }} validateOnChange={false}>
                    <TextField id="firstName"
                        label={t("First Name")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>
                <FormField name='lastName' value={users?.lastName ?? ""} validator={(value: string) => {
                    return null
                }} validateOnChange={false}>
                    <TextField id="lastName"
                        label={t("Last Name")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>
                <FormField name='email' value={users?.email ?? ""} validator={(value: string) => {
                    if (!value) {
                        return 'Email is required.'
                    }
                    if (!validator.isEmail(value)) {
                        return 'Enter a valid email address.'
                    }
                    return null
                }} validateOnChange={false}>
                    <TextField id="email"
                        label={t("Email")}
                        autoComplete='off'
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>
                <FormField name='contactNumber' value={users?.contactNumber ?? ""} validator={(value: string) => {
                    if (!value) return "Contact number is required."
                    const newValue = matchIsValidTel(value)
                    if (!newValue) {
                        return "Contact number must be 5-15 digits long"
                    }

                    return null
                }} validateOnChange={false}>

                    <MuiTelephoneInput
                        id="contactNumber"
                        label={t("Contact Number")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}

                    />
                </FormField>
                {
                    !defaultRole && decodedTokenData.roles !== 'Admin' && decodedTokenData.roles !== 'SuperAdmin' && <FormField name='role' value={users?.role ?? selectedRole} validator={(value: string) => {
                        if (!value) return "Role is required."
                        return null
                    }} validateOnChange={false}>
                        <TextField id="role"
                            label={t("Role")}
                            select
                            variant="outlined"
                            sx={{ mb: 10 }}
                            fullWidth={true}
                            readonly={decodedTokenData.roles === 'SuperAdmin'}
                            onChange={(e: any) => {
                                setSelectedRole(e.target.value);
                            }}

                        >
                            {decodedTokenData.roles === 'FVRZAdmin' ? (
                                SuperAdminRoles.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {t(option.label)}
                                    </MenuItem>
                                ))
                            ) : (
                                ClubAdminRoles.map((option: any) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {t(option.label)}
                                    </MenuItem>
                                ))
                            )}
                        </TextField>
                    </FormField>
                }
                {
                    decodedTokenData.roles === 'SuperAdmin' && <FormField name='role' value={users?.role ?? 'SuperAdmin'} validator={(value: string) => {
                        if (!value) return "Role is required."
                        return null
                    }} validateOnChange={false}>
                        <TextField id="role"
                            label={t("Role")}
                            select
                            variant="outlined"
                            sx={{ mb: 10 }}
                            fullWidth={true}
                            readonly={decodedTokenData.roles === 'SuperAdmin'}
                        >
                            <MenuItem key='SuperAdmin' value='SuperAdmin'>
                                {t('SuperAdmin')}
                            </MenuItem>

                        </TextField>
                    </FormField>
                }
                {
                    decodedTokenData.roles === 'Admin' && <FormField name='role' value={users?.role ?? ""} validator={(value: string) => {
                        if (!value) return "Role is required."
                        return null
                    }} validateOnChange={false}>
                        <TextField id="role"
                            label={t("Role")}
                            select
                            variant="outlined"
                            sx={{ mb: 10 }}
                            fullWidth={true}
                            onChange={(e: any) => {
                                setSelectedRole(e.target.value);
                            }}
                        >
                            {
                                AdminRoles.map((option: any) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {t(option.label)}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </FormField>
                }

                {
                    selectedRole == 'AssociationAdmin' && (
                        <FormField name='designation' value={users?.designation ?? selectedDesignation} validator={(value: string, data: any) => {
                            if ((data.role?.value ?? selectedRole) !== 'SuperAdmin' && !value) return "Designation selection is required.";
                            return null
                        }} validateOnChange={false}>
                            <TextField id="designation"
                                label={t("Designation")}
                                variant="outlined"
                                select
                                sx={{ mb: 10 }}
                                fullWidth={true}
                                onChange={(e: any) => {
                                    setSelectedDesignation(e.target.value)
                                }}
                            >
                                {designations.map((option) => (
                                    <MenuItem key={option.value} value={option.value} >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}

                            </TextField>
                        </FormField>
                    )
                }

                {
                    latestClubId === undefined && selectedRole !== 'FVRZAdmin' && decodedTokenData.roles === 'FVRZAdmin' && !editMode && (
                        <FormField name='clubId' value={users?.clubId ?? ""} validator={(value: string, data: any) => {
                            if (data.role?.value !== 'SuperAdmin' && !value) return "Club selection is required.";
                            return null
                        }} validateOnChange={false}>
                            <TextField id="club"
                                label={t("Club")}
                                select
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                                onChange={(e: any) => {
                                    getAssociationData(parseInt(e.target.value))
                                }}


                            >
                                {clubsData ? clubsData.getAllClubs.clubs.map((club: any) => (
                                    <MenuItem key={"club" + club.id} value={club.id}>
                                        {club.clubName}
                                    </MenuItem>
                                )) : <MenuItem></MenuItem>}

                            </TextField>
                        </FormField>

                    )
                }

                {
                    selectedRole === 'AssociationAdmin' && !editMode && latestAssociationId === undefined && decodedTokenData.roles !== "AssociationAdmin" && (
                        <FormField name='associationId' value={users?.associationId ?? ""} validator={(value: string, data) => {
                            if (data.role?.value === 'AssociationAdmin' && !value) return "Association selection is required.";
                            return null
                        }} validateOnChange={false}>
                            <TextField id="association"
                                label={t("Association")}
                                select
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                                onChange={(e: any) => {

                                }}
                            >
                                {associationData ? associationData?.getAllAssociations?.associations?.map((association: any) => (
                                    <MenuItem key={"assoc" + association.id} value={association.id} >
                                        {association.associationName}
                                    </MenuItem>
                                )) : <MenuItem></MenuItem>}


                            </TextField>
                        </FormField>
                    )

                }
                {
                    selectedRole !== 'AssociationAdmin' && selectedRole !== 'Evaluator' && !editMode  && (
                        <FormField name='password' validator={(value: string, data: any) => {
                            if (data.role?.value !== 'AssociationAdmin' && data.role?.value !== 'Evaluator') {
                                if (!value) return "Password is required.";
                                let error = checkPassword(value);
                                if (error) return error;
                            }
                            return null
                        }} validateOnChange={false}>
                            <PasswordField
                                id="password"
                                label={t("Password")}
                                wrapperSx={{ mb: 9 }}
                                variant="outlined"
                                fullWidth={true}
                            />
                        </FormField>

                    )
                }
                <Divider sx={{ mx: -24, mb: 20 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" color="secondary" onClick={onCloseUser} >{t('Cancel')}</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
                    </Grid>
                </Grid>
            </FormGroup>
        </div>
    )
}
