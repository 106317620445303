import { Hidden, TextFieldProps, TextFieldVariants, TextField as TF } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import moment, { Moment } from 'moment';
import { parseDate } from '../../utils/util-functions';
import 'moment/locale/de';
import dayjs from 'dayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers';




export default function DateTime(props: any) {

    const { ...DFProps } = props
    const [value, setValue]: any = useState(null);


    useEffect(() => {
        if(props?.value) {
            setValue(dayjs(props?.value))
        }
    }, [props?.value])


    const overrideOnChange = (event: any) => {

        
        if (props.onChange) {
            props.onChange({
                target: {
                    value: event,
                    moment: event
                }
            })
        }
    }



    return (
        // <TF {...{...TFProps, onChange: overrideOnChange, error: props.error ?? false, helperText: props.helperText ?? " "}} />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DemoContainer components={['DateTimePicker']}>
            <MobileDateTimePicker
                value={value}
                label={props.label}
                onChange={overrideOnChange}
                closeOnSelect={true}
                disabled={props?.disabled ?? false}
                className={props?.className ?? null}
                autoFocus={props?.autoFocus ?? false}
                maxDate={props?.maxDate ? dayjs(props.maxDate) : null}
                minDate={props?.minDate ? dayjs(props.minDate) : null}
                
                format="DD.MM.YYYY HH:mm"
                sx={{ ...(props?.sx ?? {}) }}
                // sx={{marginBottom:'10px'}}
                slotProps={{
                    textField: {
                        fullWidth: props?.fullWidth ?? true,
                        error: props?.error ?? false,
                        helperText: props?.helperText ?? " ",
                    },
                    calendarHeader: {
                        sx: {
                            fontSize: '12px',
                        },
                        className: "trixian_header",
                    },
                    nextIconButton: {
                        sx: {
                            fontSize: '12px',
                            marginLeft: '5px',
                        },
                        className: "trixian_nextIconButton"

                    },

                    day: {
                        sx: {
                            fontSize: '12px',
                        },
                        className: "trixian_day"
                    }

                }}

            />
            </DemoContainer>
        </LocalizationProvider>
    )
}
