import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Divider, InputLabel, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DownloadOutlined } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function FileUpload({ handleSubmit, handleChange, onClose, fileName }: any) {

    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement>(null);


    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <div>
            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" marginBottom={25} useFlexGap flexWrap="wrap">
                <Button
                    component="label"
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}



                >
                    {t('Upload File')}
                    <VisuallyHiddenInput type="file" ref={fileInputRef} onChange={(event) => {
                        handleChange(event);

                        resetFileInput();
                    }} />
                </Button>

            </Stack>

            <p style={{ fontSize: 14, marginBottom: 10 }}>{fileName}</p>

            <Stack marginBottom={25}>

                <p style={{ fontSize: 16 }} >Download sample &nbsp; <a style={{ textDecoration: 'none', color: '#fff' }} rel="noopener noreferrer" href={'/spieler_daten_format.xlsx'} target="__blank">Click here</a></p>

            </Stack>

            <Divider sx={{ mx: -24, mb: 20 }} />

            <Grid container justifyContent={"flex-end"} columnGap={10}>
                <Grid item xs={"auto"}>
                    <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
                </Grid>
                <Grid item xs={"auto"}>
                    <Button type="submit" variant="contained" onClick={handleSubmit} color="primary">{t('Save')}</Button>
                </Grid>
            </Grid>

        </div>
    );
}
