import { TextFieldProps, TextFieldVariants, TextField as TF } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import { parseDate } from '../../utils/util-functions';
import 'moment/locale/de';




export default function DateField(props:any) {

    const {...DFProps} = props
    const [value, setValue]: any = useState(null);

    useEffect(() => {
        
        setValue(parseDate(props?.value))
        
    }, [props?.value])



    const overrideOnChange = (event: any) => {
        if (props.onChange) {
            let value = event?.format('DD.MM.YYYY');
            props.onChange({
                target: {
                    value,
                    moment: event
                }
            })
        }
    }


  return (
    // <TF {...{...TFProps, onChange: overrideOnChange, error: props.error ?? false, helperText: props.helperText ?? " "}} />
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
      {/* <DemoContainer components={['DatePicker']}> */}
        <DatePicker
            value={value}
            label={props.label} 
            onChange={overrideOnChange} 
            closeOnSelect={true}
            disabled={props?.disabled ?? false}
            className={props?.className ?? null}
            autoFocus={props?.autoFocus ?? false}
            maxDate={props?.maxDate ?? null}
            minDate={props?.minDate ?? null}
    
            sx={{...(props?.sx ?? {})}} 
            slotProps={{ 
                textField: {
                    fullWidth: props?.fullWidth ?? true,
                    error: props?.error ?? false,
                    helperText: props?.helperText ?? " ",
                },
                calendarHeader: {
                    sx: {
                        fontSize: '12px',
                    },
                    className: "trixian_header",
                },
                nextIconButton: {
                    sx: {
                        fontSize: '12px',
                        marginLeft: '5px',
                    },
                    className: "trixian_nextIconButton"
                
                },


                
                
                day: {
                    sx: {
                        fontSize: '12px',
                    },
                    className: "trixian_day"
                }
                
             }}

            format='DD.MM.YYYY' />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  )
}
