import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useMutation, gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next'
import Addassociation from './add-association';
import AddUser from './add-customer';



const GET_CLUBS_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int) {
    getAllClubs(search: $search, page: $page, pageSize: $pageSize) {
      clubs {
        clubAddress
        clubName
        contactNumber
        id
      }
      errors
      success
      totalClubs
    }
  }
        `


const steps = ['Add Customer', 'Add user'];

export default function CustomerStepper({ association, onSave, onClose, role }: any) {
    const [activeStep, setActiveStep] = React.useState(0);
    const { refetch: clubRefetch } = useQuery(GET_CLUBS_QUERY, { skip: true });
    const [latestAssociationId, setLatestAssociationId] = React.useState<any>(null);
    const [latestClubId,setLatestClubId] = React.useState<any>(null);

    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = (ass:any) => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? 
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setLatestAssociationId(ass.id)
        setActiveStep(newActiveStep);
    };

   

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
       
    };


  const { t } = useTranslation();


    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit">
                            {t(label)}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>
                {
                    activeStep === 0 && <Addassociation association={association} onSave={()=>{}} onClose={onClose}  handleNext={handleNext}/>
                }
                {
                    activeStep === 1 && <AddUser defaultRole="AssociationAdmin" latestAssociationId={latestAssociationId} latestClubId={latestClubId} onSave={onSave} onClose={onClose} role={role}/>
                }


            </div>
        </Box>
    );
}