import React from 'react';

export type BreadcrumbType  = {
    name: string;
    path?: string;
}

type PageNameContextType = {
    pageName: string;
    setPageName: (name: string) => void;
    breadcrumbs: BreadcrumbType[];
    setBreadcrumbs: (breadcrumbs: BreadcrumbType[]) => void;
};

const PageMetaContext = React.createContext<PageNameContextType | undefined>(undefined);


export const PageMetaProvider = PageMetaContext.Provider;
export const PageMetaConsumer = PageMetaContext.Consumer;
export default PageMetaContext;